import axios from "axios";
import Cookies from "universal-cookie";
import { API_URL, COOKIE_PREFIX, WEB_APP_URL, BETA_USERS } from "../env";
import {
  UNAUTH_USER,
  AUTH_ERROR,
  GET_PROFILE,
  GET_VORTO_IDS,
  GET_WEB_APP_URL,
} from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as auth from './auth';

const cookies = new Cookies();

export const getProfile = () => async (dispatch) => {
  dispatch(actions.requestData(GET_PROFILE));

  const betaUsers = await BETA_USERS();
  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const webAppUrl = await WEB_APP_URL();
  
  dispatch(actions.receiveData(webAppUrl, GET_WEB_APP_URL));

  axios.get(`${apiUrl}profile`, await actions.getAxiosConfig())
    .then((response) => {

      // Stop unauthorized beta users from accessing the beta site
      if (window.location.hostname.indexOf("beta") > -1 && !betaUsers.includes(response.data.email)) {
        dispatch(actions.receiveError("Unauthorized", GET_PROFILE));
      }
      else {
        dispatch(actions.receiveData(response.data, GET_PROFILE));
        dispatch(auth.getCompany());
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, GET_PROFILE));
      }
    });
};

export const getVortoIds = () => async (dispatch) => {
  dispatch(actions.requestData(GET_VORTO_IDS));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}vorto/repository`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, GET_VORTO_IDS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, GET_VORTO_IDS));
      }
    });
};

export const signoutUser = () => ({
  type: UNAUTH_USER
});

export const authError = error => ({
  type: AUTH_ERROR,
  payload: error
});