
// Type of data
export enum WidgetValueType {
  Count = "Count",
  Percentage = "Percentage",
  Minutes = "Minutes"
}

// Type of graph
export enum WidgetGraphType {
  HorizontalBar = "HorizontalBar",
  VerticalBar = "VerticalBar",
  ChangeBarGraph = "ChangeBarGraph",
  ChangeBarGraph2 = "ChangeBarGraph2",
  Sparkline = "Sparkline",
  KPIBox = "KPIBox",
  ValueList = "ValueList",
  DynamicValueList = "DynamicValueList",
  HorizontalCompareMonths = "HorizontalCompareMonths",
  UtilizationSparklineMonth = "UtilizationSparklineMonth",
  MonthlyUtilizationDistribtion = "MonthlyUtilizationDistribtion",
  MonthlyDurationEmpty = "MonthlyDurationEmpty",
  MonthlyDurationFull = "MonthlyDurationFull",
  MonthlyDurationAtCapacity = "MonthlyDurationAtCapacity",
}