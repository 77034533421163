import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row } from "react-grid-system";
import moment from "moment";
import { get, isEmpty } from "lodash";
import Table from "../../components/Table";
import Loader from "../../components/Loader";
import FrontPageSearchBox from "../../components/FrontPageSearchBox";
import * as dashboardActions from "../../actions/dashboards";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as selectedActions from "../../actions/selected";
import styled from "styled-components";

class SearchSensors extends Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.history.location.search);

    this.state = {
      search: params.get("search") ?? "",
      sortBy: params.get("sortBy") ?? "name",
      sortOrder: params.get("sortOrder") ?? "asc",
      offset: params.get("offset") ?? 0,
      showOffline: params.get("offline") === "true",
      limit: 100
    };

    this.storeQueryParams = this.storeQueryParams.bind(this);
    this.performSearch = this.performSearch.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
    this.onRemoveTagClick = this.onRemoveTagClick.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
    this.onSensorClick = this.onSensorClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onToggleAllSensors = this.onToggleAllSensors.bind(this);

    if (get(this.props, "offlineSensors.sensors", []).length === 0) {
      this.props.getOfflineSensors();
    }

    if (isEmpty(this.props.locationHierarchy)) {
      this.props.getLocationHierarchy();
    }

    // Search if query parameter is set and we don't have any search results
    const currentQueryParams = this.props.currentQueryParams;
    if (currentQueryParams?.search !== this.state.search || currentQueryParams?.offline !== this.state.offline) {
      this.performSearch();
    }

    this.storeQueryParams();
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Sensor search`;
  }

  storeQueryParams() {
    const queryParams = {};
    if (!isEmpty(this.state.search)) {
      queryParams.search = this.state.search;
    }

    if (this.state.sortBy !== "name") {
      queryParams.sortBy = this.state.sortBy;
    }

    if (this.state.sortOrder !== "asc") {
      queryParams.sortOrder = this.state.sortOrder;
    }

    if (this.state.offset) {
      queryParams.offset = this.state.offset;
    }

    if (this.state.showOffline) {
      queryParams.offline = true;
    }

    this.props.setSensorSearch(queryParams);
  }

  performSearch() {
    if (this.state.showOffline) {
      const params = new URLSearchParams(this.props.history.location.search);
      
      if (!isEmpty(this.state.search)) {
        params.set("search", this.state.search);
      }

      if (this.state.sortBy !== "name") {
        params.set("sortBy", this.state.sortBy);
      }

      if (this.state.sortOrder !== "asc") {
        params.set("sortOrder", this.state.sortOrder);
      }

      params.set("offline", true);
      
      this.props.history.push({ 
        pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
        search: params.toString()
      });

      this.storeQueryParams();
    }
    else if (this.state.search !== null && this.state.search !== "") {
      this.onFetchData();

    }
    else {
      this.onSearchClear();
    }
  }

  onFetchData() {
    const queryParams = {};
    const params = new URLSearchParams(this.props.history.location.search);

    if (!isEmpty(this.state.search)) {
      queryParams.search = this.state.search;
      params.set("search", this.state.search);
    }

    if (this.state.sortBy !== "name") {
      queryParams.sortBy = this.state.sortBy;
      params.set("sortBy", this.state.sortBy);
    }

    if (this.state.sortOrder !== "asc") {
      queryParams.sortOrder = this.state.sortOrder;
      params.set("sortOrder", this.state.sortOrder);
    }

    if (this.state.offset) {
      queryParams.offset = this.state.offset;
      params.set("offset", this.state.offset);
    }

    queryParams.limit = this.state.limit;

    this.props.getSensors(queryParams);
    
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.storeQueryParams();
  }

  onSearchKeyPress(event) {
    if (event.key && event.key === "Enter") {
      event.preventDefault();
      this.performSearch();
    }
  }

  onSearchChange(value) {
    this.setState({ search: value, offset: 0 });
  }

  onSearchClear() {
    this.setState({ search: "", showOffline: false, offset: 0 }, this.storeQueryParams);
    this.props.clearAllSensors();

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: ""
    });
  }

  onRemoveTagClick(tag) {
    if (tag.id === "offlineSensors") {
      this.setState({ showOffline: false }, this.performSearch);
    }
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder }, () => {
      if (!this.state.showOffline) {
        this.performSearch();
      }
    });
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.performSearch);
  }

  onSensorClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {

          let link = `/companies/${this.props.match.params.companyId}/sensors/${row.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onToggleSensor(row) {
    if (this.props.selectedSensors[row.original.id] !== undefined && this.props.selectedSensors[row.original.id]) {
      this.props.deselectSensor(row.original.id);
    }
    else {
      this.props.selectSensor(row.original);
    }
  }

  onToggleAllSensors() {
    let sensors;
    const currentQueryParams = this.props.currentQueryParams;
    if (currentQueryParams && currentQueryParams.offline) {
      sensors = get(this.props.offlineSensors, "sensors", []);

      if (!isEmpty(currentQueryParams.search)) {
        sensors = sensors.filter(sensor => {
          const foundName = sensor.name && sensor.name.toLowerCase().includes(currentQueryParams.search.toLowerCase());
          const foundModel = sensor.model && sensor.model.toLowerCase().includes(currentQueryParams.search.toLowerCase());
          const foundVendor = sensor.vendor && sensor.vendor.toLowerCase().includes(currentQueryParams.search.toLowerCase());
          return foundName || foundModel || foundVendor;
        });
      }
    }
    else {
      sensors = get(this.props.sensors, "metricData.results", []);
    }

    const allSensorsAreSelected = !isEmpty(sensors) && sensors.every(sensor => this.props.selectedSensors[sensor.id] !== undefined && this.props.selectedSensors[sensor.id]);

    if (allSensorsAreSelected) {
      this.props.deselectSensors(sensors);
    }
    else {
      this.props.selectSensors(sensors.map(sensor => {
        return { ...sensor };
      }));
    }
  }

  sensorTable() {

    let sensors;
    let count;

    const currentQueryParams = this.props.currentQueryParams;
    if (currentQueryParams && currentQueryParams.offline) {
      sensors = get(this.props.offlineSensors, "sensors", []);
      count = get(this.props.offlineSensors, "count", 0);

      if (!isEmpty(currentQueryParams.search)) {
        sensors = sensors.filter(sensor => {
          const foundName = sensor.name && sensor.name.toLowerCase().includes(currentQueryParams.search.toLowerCase());
          const foundModel = sensor.model && sensor.model.toLowerCase().includes(currentQueryParams.search.toLowerCase());
          const foundVendor = sensor.vendor && sensor.vendor.toLowerCase().includes(currentQueryParams.search.toLowerCase());
          return foundName || foundModel || foundVendor;
        });
      }

      // Sort sensors by sortBy and sortOrder
      sensors = sensors.sort((a, b) => {
        const norway = "nb-NO";
        if (currentQueryParams.sortBy === "name") {
          return currentQueryParams.sortOrder === "asc" ? get(a, "name", "").localeCompare(get(b, "name", ""), norway) : get(b, "name", "").localeCompare(get(a, "name", ""), norway);
        }
        else if (currentQueryParams.sortBy === "model") {
          return currentQueryParams.sortOrder === "asc" ? get(a, "model", "").localeCompare(get(b, "model", "")) : get(b, "model", "").localeCompare(get(a, "model", ""));
        }
        else if (currentQueryParams.sortBy === "vendor") {
          return currentQueryParams.sortOrder === "asc" ? get(a, "vendor", "").localeCompare(get(b, "vendor", "")) : get(b, "vendor", "").localeCompare(get(a, "vendor", ""));
        }
        else if (currentQueryParams.sortBy === "gatewayDeviceId") {
          const aGateway = a.gatewayDeviceId ? a.gatewayDeviceId : "";
          const bGateway = b.gatewayDeviceId ? b.gatewayDeviceId : "";
          return currentQueryParams.sortOrder === "asc" ? aGateway.localeCompare(bGateway) : bGateway.localeCompare(aGateway);
        }
        else if (currentQueryParams.sortBy === "lastSampleAt") {
          return currentQueryParams.sortOrder === "asc" ? moment(a.lastSampleAt).diff(moment(b.lastSampleAt)) : moment(b.lastSampleAt).diff(moment(a.lastSampleAt));
        }
      });

      // Fix reload issue
      sensors = sensors.map(sensor => sensor);
    }
    else {
      sensors = get(this.props.sensors, "metricData.results", []);
      count = get(this.props.sensors, "metricData.count", 0);
    }

    const allSensorsAreSelected = !isEmpty(sensors) && sensors.every(sensor => this.props.selectedSensors[sensor.id] !== undefined && this.props.selectedSensors[sensor.id]);

    let columns = [
      {
        id: "sensorId",
        header: "",
        accessorKey: "id",
        sortable: false,
        name: "isSelected",
        header: () => (
          <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
            <input
              id={`editCheckbox-header`}
              type="checkbox"
              className="checkbox"
              checked={allSensorsAreSelected}
              onChange={() => this.onToggleAllSensors()}
              disabled={isEmpty(sensors)}
            />
            <span className={isEmpty(sensors) ? "disabledCheckmark" : "checkmark"} />
          </label>
        ),
        cell: ({ row }) => (
          <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
            <input
              id={`editCheckbox-${row.original.id}`}
              type="checkbox"
              className="checkbox"
              checked={(this.props.selectedSensors[row.original.id] !== undefined && this.props.selectedSensors[row.original.id])}
              onChange={() => this.onToggleSensor(row)}
            />
            <span className="checkmark" />
          </label>
        ),
        width: 60
      },
      {
        header: "Name",
        accessorKey: "name",
        minWidth: 100,
        maxWidth: 1200,
        cell: ({ row }) => {
          if (!row.original.name) {
            return (<span style={{ color: "grey" }}>{`Unknown`}</span>);
          }
          else if (row.original.locationId) {
            const breadcrumbs = this.props.locationBreadcrumbs[row.original.locationId].map(breadcrumb => breadcrumb.name).join(", ");
            return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({breadcrumbs})</span></>)
          }
          return (<span title={row.original.name}>{row.original.name}</span>)
        }
      },
      {
        header: "Model",
        accessorKey: "model",
        minWidth: 100,
        maxWidth: 200,
        cell: ({ row }) => (<span>{row.original.model}</span>)
      },
      {
        header: "Vendor",
        accessorKey: "vendor",
        minWidth: 100,
        maxWidth: 200,
        cell: ({ row }) => (<span>{row.original.vendor}</span>)
      },
      {
        header: "Gateway",
        accessorKey: "gatewayDeviceId",
        minWidth: 100,
        maxWidth: 300,
        cell: ({ row }) => (<span>{row.original.gatewayDeviceId}</span>)
      },
      {
        id: "arrow",
        header: "",
        accessorKey: "properties",
        sortable: false,
        className: "pull-right",
        width: 60,
        cell: () => <div className="arrow" />
      }
    ];

    if (this.state.showOffline) {
      // insert lastSampleAt column at next to last position
      columns.splice(columns.length - 1, 0, {
        header: "Last sample",
        accessorKey: "lastSampleAt",
        width: 140,
        cell: ({ row }) => (<span>{row.original.lastSampleAt ? moment(row.original.lastSampleAt).format("DD/MM/YY, HH:mm") : "N/A"}</span>)
      });
    }

    return (
      <Table
        data={sensors}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset ?? 0}
        limit={this.state.limit}
        count={count}
        onOffsetChange={this.onOffsetChange}
        onSortedChange={this.onSortedChange}
        noDataText={this.props.isLoadingSensors ? "" : (isEmpty(this.props.currentQueryParams?.search) ? "The search cannot be empty" : "No sensors found")}
        columns={columns}
        getTdProps={this.onSensorClick}
        getTrGroupProps={this.getTrProps}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoadingSensors}
      />
    );
  }

  render() {
    console.log("Home.FrontPage.state", this.state)
    console.log("Home.FrontPage.props", this.props)

    if (this.props.isLoadingLocations) {
      return <Loader fullScreen />;
    }

    let customTags = [];

    // Add offline tag to searchbox, if state.showOffline is set
    if (this.state.showOffline) {
      customTags.push({
        id: "offlineSensors",
        name: "Offline sensors",
        colorTheme: "#BD271F"
      });
    }

    const searchBox = (
      <FrontPageSearchBox
        query={{ customTags, text: this.state.search, placeholder: "What are you looking for?" }}
        onQueryChange={this.onSearchChange}
        onSearchClick={this.performSearch}
        onKeyDown={this.onSearchKeyPress}
        onClear={this.onSearchClear}
        onRemoveTagClick={this.onRemoveTagClick}
      />
    );

    let heading = (
      <div style={{ marginLeft: "auto", marginRight: "auto", flexGrow: 1, maxWidth: "800px", marginTop: "60px", marginBottom: "60px" }}>
        {searchBox}
      </div>
    );

    let searchTable = this.sensorTable();

    return (
      <OuterContainer>
        <InnerContainer>
          { heading }
          <Row>
            <WidthContainer>
              <Block>
                { searchTable }
              </Block>
            </WidthContainer>
          </Row>
        </InnerContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.auth.selectedCompany,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    sensors: state.sensors,
    currentQueryParams: state.sensors.currentQueryParams,
    offlineSensors: state.offlineSensors.data,
    isLoadingLocations: state.loading.locations,
    isLoadingSensors: state.loading.sensors,
    selectedSensors: state.selected.sensors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getOfflineSensors: dashboardActions.getOfflineSensors,
    getSensors: sensorActions.getAllSensors,
    setSensorSearch: sensorActions.setSensorSearch,
    clearAllSensors: sensorActions.clearAllSensors,
    getLocationHierarchy: locationActions.getLocationHierarchy,
   
    selectSensor: selectedActions.selectSensor,
    deselectSensor: selectedActions.deselectSensor,
    selectSensors: selectedActions.selectSensors,
    deselectSensors: selectedActions.deselectSensors,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchSensors);

const OuterContainer = styled.div`
  position: relative;
  display: block;
  height: calc(100vh - 115px);
  width: 100%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }

  p, ol {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  width: ${props => props.$showSideBar ? "calc(100% - 345px)" : "100%"};
  max-height: 100%;
  height: 100%;
  overflow: auto;

  padding-left: ${props => props.$mobile ? "20px" : "40px"};
  padding-right: ${props => props.$mobile ? "20px" : "40px"};
  padding-bottom: 40px;
  box-sizing: border-box;
`;

const Block = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  padding: 10px 20px;
  box-sizing: border-box;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
`;

const WidthContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;
  flex-wrap: wrap;
`;