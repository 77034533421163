import React, { Component } from "react";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { SafeTripleColors } from "../../../constants/colors";
import { SimpleGraph, GraphType, TimePeriod, GraphScaleType, GraphTimeSpan, StepSize } from "ui";
import styled from "styled-components";
import strings from "../strings";

class PeopleCountYearOverYear extends Component {

  render() {

    // Do not render if there are no queries
    if (isEmpty(this.props.queries)) {
      return null;
    }
    
    // Get graph name
    const graphKey = "month";

    // Get localized strings
    strings.setLanguage(this.props.language || "en");

    // Add either workplace categories or custom tags as entity names
    let entities = {};
    this.props.queries.forEach(query => {
      let selectedCustomTagId = query.customTagId;
  
      // Add custom tag
      if (selectedCustomTagId && selectedCustomTagId !== "all") {
        const customTag = this.props.customTags.find(tag => tag.id === selectedCustomTagId);
        if (customTag) {
          entities[selectedCustomTagId] = { name: customTag.name };
        }
      }
      else {
        // Add location
        entities[query.locationId] = { name: get(query, "location.name", "") };
      }
    });

    // Get maximum value for all graphs
    let min = 0;
    let max = 10;
    const allPossibleKeys = [...this.props.graphDataKeys.filter(dk => dk.show).map(dk => dk.key), "capacity"];
    if (this.props.queries && this.props.queries.length > 0) {
      max = Math.max(...this.props.queries.map(query => {
        let hashKey = `${graphKey}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-true`;
        hashKey += query.graphScaleType === "percentage" ? "-yearOverYear-percentage" : "-yearOverYear";
        const samples = this.props.queryData[hashKey] || [];
  
        // Sample data is an array of objects with keys: avgAvg, avgPeak, peakPeak, capacity
        if (samples.length > 0) {
          
          // Get all values in an array, filtering out undefined values
          const allValues = samples.flatMap(sample => 
            allPossibleKeys.map(key => sample[key]).filter(value => value !== undefined)
          );

          // Return the maximum value, ensuring we consider at least 0 if allValues is empty
          return allValues.length > 0 ? Math.max(...allValues, 0) : 0;
        }
  
        return query.graphScaleType === "percentage" ? 100 : 10;
      }));
    }
    // Add 10% to the maximum value and round up to closest 10 - to make the graphs easier to compare
    max = Math.ceil(max * 1.1 / 10) * 10;

    // Estimate a dynamic step size based on the magnitude of the maxValue
    const magnitude = Math.pow(10, Math.floor(Math.log10(max))); // Get the scale of the maxValue
    const dynamicStepSize = magnitude / 2; // Adjust as needed (e.g., /2 or /5 for finer steps)

    // Round the maxValue up to the nearest multiple of the dynamic step size
    max = Math.ceil(max / dynamicStepSize) * dynamicStepSize;

    let ticks = [];

    // Create month array
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach(month => {
      ticks.push(moment().set("month", month));
    });

    let allUniqueYears = { "capacity": "capacity" };
    this.props.queries.forEach((query) => {

      const startDate = moment(get(query.dateRanges, "[0].startDate", null));
      const endDate = moment(get(query.dateRanges, `[${query.dateRanges.length-1}].endDate`, null));

      // Create ticks (x-axis) for labels and datasets for each date range
      let startMoment = startDate.clone();
      let endMoment = endDate.clone();
      
      // Make tick contain whole month
      startMoment = startMoment.startOf("month");
      endMoment = endMoment.endOf("month");

      // Get all years in the time span startMoment - endMoment
      let currentYear = startMoment.year();
      while (currentYear <= endMoment.year()) {
        allUniqueYears[currentYear] = currentYear;
        currentYear++;
      }
    });

    // Get the single entity name
    const entityName = get(Object.values(entities), "[0].name", undefined);

    // Add styling to each entity
    const datasetStyles = {};
    Object.keys(allUniqueYears).forEach((year, index) => {
      if (year === "capacity") {
        datasetStyles[year] = { label: "Capacity", keys: { "capacity": { borderColor: "#222", backgroundColor: "#222", fill: false } } };
      }
      else {
        datasetStyles[year] = { label: `${entityName} ${year}`, keys: {} };

        // Add color to each data key
        this.props.graphDataKeys.forEach((keyObject, keyIndex) => {
          const color = SafeTripleColors[index % 11][keyIndex];

          if (keyObject.show) {
            datasetStyles[year].keys[keyObject.key] = { borderColor: color, backgroundColor: color, fill: false };
          }
        });
      }
    });

    return (
      <Row>
        {
          this.props.queries.map((query, index) => {
            let hashKey = `${graphKey}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-true`;

            const loadingStatus = this.props.queryLoading[hashKey] ?? { type: "not started" };
            const isLoading = loadingStatus.type === "loading";

            hashKey += query.graphScaleType === "percentage" ? "-yearOverYear-percentage" : "-yearOverYear";
            let samples = this.props.queryData[hashKey] || [];

            const startDate = moment(get(query.dateRanges, "[0].startDate", null));
            const endDate = moment(get(query.dateRanges, `[${query.dateRanges.length-1}].endDate`, null));
            const title = strings.formatString(strings.peopleCount.graphTitle, get(query.location, "name", "?") || strings.unknown, startDate.format("DD/MM/YYYY"), endDate.format("DD/MM/YYYY"));

            const y = { min: 0, max: max, zoomMin: min, zoomMax: max, unit: query.graphScaleType === "percentage" ? "%" : "" };
            const x = {}; //{ min: get(query.dateRanges, "[0].startDate", null), max: get(query.dateRanges, `[${query.dateRanges.length-1}].endDate`, null), dateRanges: query.dateRanges };

            // Create ticks (x-axis) for labels and datasets for each date range
            let startMoment = startDate.clone();
            let endMoment = endDate.clone();
            
            // Make tick contain whole month
            startMoment = startMoment.startOf("month");
            endMoment = endMoment.endOf("month");

            // Add capacity to keys
            let allKeysInSamples = this.props.graphDataKeys.map(dk => dk.key).filter(key => key !== null);
            for (let j=0; j < samples.length; j++) {
              if (get(samples[j], "entityId", undefined) === "capacity") {
                allKeysInSamples = [...allKeysInSamples, "capacity"];
                break;
              }
            }

            // Remove duplicate keys
            allKeysInSamples = [...new Set(allKeysInSamples)];

            // Get all years in the time span startMoment - endMoment
            let uniqueYears = {};
            let currentYear = startMoment.year();
            while (currentYear <= endMoment.year()) {
              uniqueYears[currentYear] = currentYear;
              currentYear++;
            }

            // Find unique entityIds in samples
            let uniqueYearsArray = Object.keys(uniqueYears);
            uniqueYearsArray.push("capacity");
            if (uniqueYearsArray.length === 0) {
              uniqueYearsArray = [null];
            }
            uniqueYearsArray.sort();

            // console.log("hashKey", hashKey);
            // console.log("valueKeys", this.props.graphDataKeys);
            // console.log("samples", samples);
            // console.log("loadingStatus", loadingStatus);
            // console.log("query", query);

            return (
              <Col key={hashKey + index} $isGraph $expanded={this.props.queries.length === 1}>
                <SimpleGraph
                  dataType="peopleCount"
                  source="report"
                  title={title}
                  language={this.props.language}
                  locationName={get(query.location, "name", "?")}
                  samples={samples}
                  aspectRatio={2.2}
                  y={y}
                  x={x}
                  ticks={ticks}
                  uniqueEntityIds={uniqueYearsArray}

                  allKeys={allKeysInSamples}
                  datasetStyles={datasetStyles}
                  
                  stepSize={StepSize.Month}
                  timePeriod={TimePeriod.MonthOfYear}
                  graphType={GraphType.Line}
                  graphScaleType={query.graphScaleType}
                  graphShowLabels={query.graphShowLabels}
                  isLoading={isLoading}
                  statusMessage={!isLoading && isEmpty(samples) ? strings.noData : undefined}
                  showLegend
                  showPNGOption
                  showCSVOption
                  toggleGraphScaleType={() => this.props.onGraphScaleTypeChanged(index, query.graphScaleType === "count" ? "percentage" : "count")}
                  toggleGraphShowLabels={() => this.props.onGraphShowLabelsChanged(index, !query.graphShowLabels)}
                />
              </Col>
            );
          })
        }
      </Row>
    );
  }
}

export default PeopleCountYearOverYear;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  padding-bottom: 40px;
`;

// Set a fixed width for the graphs
// This is to prevent the graphs from being too small on small screens
// and show the graphs side by side on large screens
const Col = styled.div`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  min-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "508px" : "200px")};
  max-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "calc(50% - 5px)" : "100%")};

  @media (max-width: 1600px) {
    flex-basis: 100%;
    width: 100%;
    min-width: 200px;
    max-width: 100%;
  }
`;