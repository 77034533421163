import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";
import { sortSensorTypes, sensorTypeIgnoreList } from "../dashboardHelpers";
import { flattenHierarchy } from "../locationHelpers";

const initialState = {
  id: null,
  name: null,
  queries: [],
  previousFilter: [],
  locations: [],
  hierarchy: [],
  flatHierarchy: {},
  sensorTypes: [],
  isLoadingFilter: false,
  hasLoadedFilter: false,
  hasSearchedLocations: false,
};

export default function filterReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.QUERY_LOCATIONS) {
        return {...state, locations: [], sensorTypes: [], previousFilter: action.metadata, hasSearchedLocations: false };
      }

      if (action.fetchType === types.LOAD_FILTER) {
        return {...state, isLoadingFilter: true, hasLoadedFilter: false, hasSearchedLocations: false };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.QUERY_LOCATIONS) {
        let filter = { or: state.queries };

        if (get(filter, "or", []).length === 0 ||
            (get(filter, "or", []).length === 1 && get(filter, "or[0].and", []).length === 0) ||
            (get(filter, "or", []).length === 1 && get(filter, "or[0].and", []).length === 1 && get(filter, "or[0].and[0].or", []).length === 0)) {

          // If filter is empty, add top locations
          const topLocations = state.hierarchy.map(location => ({ property: "_id", value: location.id, operator: "eq", options: { includeDescendants: true }}));
          const allLocationsFilter = { or: [{ and: [{or: topLocations }] }] };
          if (JSON.stringify(allLocationsFilter) !== JSON.stringify(action.metadata)) {
            return state;
          }
        }
        else if (JSON.stringify(filter) !== JSON.stringify(action.metadata)) {
          return state;
        }

        // Create a complete list of sensor types for all locations in search results
        let sensorTypes = [];
        action.payload.results.some(location => {
          sensorTypes = [...new Set([...sensorTypes, ...state.flatHierarchy[location._id].recordedProperties ?? []])]; 
        });

        // Replace footfallIn/Out with peopleCount
        if (sensorTypes.includes("footfallCountIn") && sensorTypes.includes("footfallCountOut")) {
          sensorTypes = sensorTypes.filter(type => !["footfallCountIn", "footfallCountOut", "egress", "ingress", "peopleCount"].includes(type));
          sensorTypes = [...sensorTypes, "peopleCount"];
        }

        // Replace motionCount with occupiedMinutes
        if (sensorTypes.includes("motionCount")) {
          sensorTypes = sensorTypes.filter(type => !["occupiedMinutes", "motionCount"].includes(type));
          sensorTypes = [...sensorTypes, "occupiedMinutes"];
        }

        // Ignore spesific sensor types
        sensorTypes = sensorTypes.filter(sensorType => !sensorTypeIgnoreList.includes(sensorType));

        // Sort sensors by type
        sensorTypes = sortSensorTypes(sensorTypes);
        
        return {...state, locations: action.payload.results, sensorTypes, hasSearchedLocations: true };
      }

       // Add top locations to initial filter
      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {
        let newState = {...state};

        if (state.queries.length === 0) {
          const locationFilter = action.payload.map(location => ({ property: "_id", value: location.id, operator: "eq", options: { includeDescendants: true }}));
          newState.queries = [{ and: [{or: locationFilter }] }];
        }

        // Flatten hierarchy
        let flatHierarchy = {};
        if (action.payload.length > 0) {
          flattenHierarchy(action.payload, [], flatHierarchy);
        }

        newState.hierarchy = action.payload;
        newState.flatHierarchy = flatHierarchy;
        
        return newState;
      }
      
      if (action.fetchType === types.LOAD_FILTER) {
        const newFilter = action.payload;
        if (action.payload.filter) {
          const newQueries = JSON.parse(action.payload.filter);

          // If filter is equal to previous filter, do not update filter
          if (JSON.stringify(get(newQueries, "or", [])) === JSON.stringify(state.queries)) {
            return {...state, id: newFilter.id, name: newFilter.name, isLoadingFilter: false, hasLoadedFilter: true, hasSearchedLocations: true };
          }

          newFilter.filter = newQueries;
          return {...state, id: newFilter.id, name: newFilter.name, queries: newFilter.filter.or, locations: [], sensorTypes: [], isLoadingFilter: false, hasLoadedFilter: true };
        }
        else {
          // Do not reset queries if filter is empty - we fill it from the location hierarchy
          return {...state, id: null, name: null, locations: [], sensorTypes: [], isLoadingFilter: false, hasLoadedFilter: true };
        }
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.QUERY_LOCATIONS) {
        if (JSON.stringify(state.previousFilter) === JSON.stringify(action.metadata)) {
          toast.error(`${statusCode}: Could not find any locations`); 
          return {...state, locations: [], sensorTypes: [], hasSearchedLocations: true };
        }
      }
      
      return state;
    }

    case types.UPDATE_FILTER_DRAFT: {
      // console.log("updateFilterDraft", action.payload);
      let newState = {...state , queries: action.payload.queries, locations: [], sensorTypes: [] };
      if (action.payload.id) {
        newState.id = action.payload.id;
      }
      if (action.payload.name) {
        newState.name = action.payload.name;
      }
      return newState;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
