import React, { useState, useEffect, useRef } from "react";
import { Sparklines, SparklinesLine, SparklinesCurve } from "react-sparklines";
import styled from "styled-components";

interface SparklineProps {
  data: any[];
  color: string;
  description: string;
}

const Sparkline: React.FC<SparklineProps> = ({ data, color, description }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 100, height: 70 }); // Default size
  const values = data.flatMap(item => item.value ?? 0); //.filter(value => value !== null && value !== undefined);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    // Create and observe the container for size changes
    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Initial resize on mount
    handleResize();

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <Container ref={containerRef} >
      <Graph>
        <Background>
          <Sparklines data={values} width={size.width} height={size.height} margin={5} min={-0.5} max={1.5}>
            {/* <SparklinesCurve color={color} style={{ fill: "none" }} /> */}
            <SparklinesLine color={color} style={{ fill: "none" }} />
          </Sparklines>
        </Background>
        <DescriptionRow>
          <Description>{data[0].description}</Description>
          <Description>{data[data.length-1].description}</Description>
        </DescriptionRow>
      </Graph>
      { description && <GraphDescription>{description}</GraphDescription> }
    </Container>
  );
};

export default Sparkline;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  // height: 70px;
`;

const Graph = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-right: 5px;
`;

const Background = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 100%;
  height: 80px;
`;

const DescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
`;

const Description = styled.div`
  font-size: 12px;
  color: #555;
  text-align: center;
  height: 16px;
`;

const GraphDescription = styled.div`
  font-size: 12px;
  color: #555;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;