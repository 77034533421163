import axios from "axios";
import Cookies from "universal-cookie";
import moment from "moment";
import get from "lodash/get";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { WPA_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const createWorkplaceQuery = (dateRanges, includeHolidays, includeWeekends, locationId, workplaceCategoryId, customTagId, summed, graphType, graphScaleType, graphTimeSpan, graphShowLabels) => async (dispatch) => {
  dispatch({ type: types.CREATE_WORKPLACE_QUERY, payload: { dateRanges, includeHolidays, includeWeekends, locationId, workplaceCategoryId, customTagId, summed, graphType, graphScaleType, graphTimeSpan, graphShowLabels }});
};

export const updateWorkplaceQuery = (id, dateRanges, includeHolidays, includeWeekends, locationId, workplaceCategoryId, customTagId, summed, graphType, graphScaleType, graphTimeSpan, graphShowLabels) => async (dispatch) => {
  dispatch({ type: types.UPDATE_WORKPLACE_QUERY, payload: { id, dateRanges, includeHolidays, includeWeekends, locationId, workplaceCategoryId, customTagId, summed, graphType, graphScaleType, graphTimeSpan, graphShowLabels }});
};

export const setWorkplaceQuery = (dateRanges, includeHolidays, includeWeekends, locationId, workplaceCategoryId, customTagId) => async (dispatch) => {
  dispatch({ type: types.SET_WORKPLACE_QUERY, payload: { dateRanges, includeHolidays, includeWeekends, locationId, workplaceCategoryId, customTagId }});
};

export const deleteWorkplaceQuery = (id) => async (dispatch) => {
  dispatch({ type: types.DELETE_WORKPLACE_QUERY, payload: { id } });
};

export const getUtilization = (hashKey, dateTrunc, dateRanges, includeHolidays, includeWeekends, locationId, workplaceCategoryId) => async (dispatch) => {

  if (dateTrunc === "intraday") {
    dispatch(actions.requestData(types.GET_UTILIZATION_INTRADAY, { hashKey, locationId, workplaceCategoryId }));
  }
  else if (dateTrunc === "day") {
    dispatch(actions.requestData(types.GET_UTILIZATION_DAYS, { hashKey, locationId, workplaceCategoryId }));
  }
  else if (dateTrunc === "weekday") {
    dispatch(actions.requestData(types.GET_UTILIZATION_WEEKDAYS, { hashKey, locationId, workplaceCategoryId }));
  }
  else if (dateTrunc === "week") {
    dispatch(actions.requestData(types.GET_UTILIZATION_WEEKS, { hashKey, locationId, workplaceCategoryId }));
  } 
  else if (dateTrunc === "month") {
    dispatch(actions.requestData(types.GET_UTILIZATION_MONTHS, { hashKey, locationId, workplaceCategoryId }));
  }

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
    
  let url = `${wpaUrl}companies/${companyId}/locations/${locationId}/utilization`;

  // Intraday has a different endpoint
  if (dateTrunc === "intraday") {
    url = `${url}-intra-day`;
  }

  // Add workplace category id if it exists
  if (workplaceCategoryId && workplaceCategoryId !== "all") {
    url = `${url}/workplace-categories/${workplaceCategoryId}`;
  }
  else {
    url = `${url}/workplace-categories`;
  }

  // Change endDates to the end of the day
  const editedDateRanges = dateRanges.map((dateRange) => (
    {
      startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateRange.endDate).format("YYYY-MM-DD")
    }
  ));

  const queryParams = {
    dateTrunc,
    dateRanges: JSON.stringify(editedDateRanges),
    includeHolidays,
    includeWeekends
  };

  // Intraday does not have dateTrunc
  if (dateTrunc === "intraday") {
    delete queryParams.dateTrunc;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      if (dateTrunc === "intraday") {
        dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_INTRADAY, { hashKey, locationId, workplaceCategoryId }));
      }
      else if (dateTrunc === "day") {
        dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_DAYS, { hashKey, locationId, workplaceCategoryId }));
      }
      else if (dateTrunc === "weekday") {
        dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_WEEKDAYS, { hashKey, locationId, workplaceCategoryId }));
      }
      else if (dateTrunc === "week") {
        dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_WEEKS, { hashKey, locationId, workplaceCategoryId }));
      }
      else if (dateTrunc === "month") {
        dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_MONTHS, { hashKey, locationId, workplaceCategoryId })); 
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } 
      else {
        if (dateTrunc === "intraday") {
          dispatch(actions.receiveError(error, types.GET_UTILIZATION_INTRADAY, { hashKey, locationId, workplaceCategoryId }));
        }
        else if (dateTrunc === "day") {
          dispatch(actions.receiveError(error, types.GET_UTILIZATION_DAYS, { hashKey, locationId, workplaceCategoryId }));
        }
        else if (dateTrunc === "weekday") {
          dispatch(actions.receiveError(error, types.GET_UTILIZATION_WEEKDAYS, { hashKey, locationId, workplaceCategoryId }));
        }
        else if (dateTrunc === "week") {
          dispatch(actions.receiveError(error, types.GET_UTILIZATION_WEEKS, { hashKey, locationId, workplaceCategoryId }));
        }
        else if (dateTrunc === "month") {
          dispatch(actions.receiveError(error, types.GET_UTILIZATION_MONTHS, { hashKey, locationId, workplaceCategoryId }));
        }
      }
    });
};

export const getPeopleCount = (hashKey, dateTrunc, dateRanges, includeHolidays, includeWeekends, locationId, customTagId, summed) => async (dispatch) => {

  if (dateTrunc === "intraday") {
    dispatch(actions.requestData(types.GET_PEOPLE_COUNT_INTRADAY, { hashKey, locationId, customTagId, summed }));
  }
  else if (dateTrunc === "day") {
    dispatch(actions.requestData(types.GET_PEOPLE_COUNT_DAYS, { hashKey, locationId, customTagId, summed }));
  }
  else if (dateTrunc === "weekday") {
    dispatch(actions.requestData(types.GET_PEOPLE_COUNT_WEEKDAYS, { hashKey, locationId, customTagId, summed }));
  }
  else if (dateTrunc === "week") {
    dispatch(actions.requestData(types.GET_PEOPLE_COUNT_WEEKS, { hashKey, locationId, customTagId, summed }));
  }
  else if (dateTrunc === "month") {
    dispatch(actions.requestData(types.GET_PEOPLE_COUNT_MONTHS, { hashKey, locationId, customTagId, summed }));
  }

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
    
  let url;
  if (customTagId && customTagId !== "all") {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count`;

    // Intraday has a different endpoint
    if (dateTrunc === "intraday") {
      url = `${url}-intra-day`;
    }

    if (summed) {
      url = `${url}-summed`;
    }
  }
  else {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/people-count`;

    // Intraday has a different endpoint
    if (dateTrunc === "intraday") {
      url = `${url}-intra-day`;
    }
  }

  // Change endDates to the end of the day
  const editedDateRanges = dateRanges.map((dateRange) => (
    {
      startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateRange.endDate).format("YYYY-MM-DD")
    }
  ));

  const queryParams = {
    dateTrunc,
    dateRanges: JSON.stringify(editedDateRanges),
    includeHolidays,
    includeWeekends
  };

  // Intraday does not have dateTrunc
  if (dateTrunc === "intraday") {
    delete queryParams.dateTrunc;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      if (dateTrunc === "intraday") {
        dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_INTRADAY, { hashKey, locationId, customTagId, summed }));
      }
      else if (dateTrunc === "day") {
        dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_DAYS, { hashKey, locationId, customTagId, summed }));
      }
      else if (dateTrunc === "weekday") {
        dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_WEEKDAYS, { hashKey, locationId, customTagId, summed }));
      }
      else if (dateTrunc === "week") {
        dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_WEEKS, { hashKey, locationId, customTagId, summed }));
      }
      else if (dateTrunc === "month") {
        dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_MONTHS, { hashKey, locationId, customTagId, summed })); 
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } 
      else {
        if (dateTrunc === "intraday") {
          dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_INTRADAY, { hashKey, locationId, customTagId, summed }));
        }
        else if (dateTrunc === "day") {
          dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_DAYS, { hashKey, locationId, customTagId, summed }));
        }
        else if (dateTrunc === "weekday") {
          dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_WEEKDAYS, { hashKey, locationId, customTagId, summed }));
        }
        else if (dateTrunc === "week") {
          dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_WEEKS, { hashKey, locationId, customTagId, summed }));
        }
        else if (dateTrunc === "month") {
          dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_MONTHS, { hashKey, locationId, customTagId, summed }));
        }
      }
    });
};


export const getWorkplaceFilters = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_WORKPLACE_FILTERS));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${wpaUrl}companies/${companyId}/filters`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_WORKPLACE_FILTERS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_WORKPLACE_FILTERS));
      }
    });
}

export const getWpaCategories = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_WORKPLACE_CATEGORIES));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${wpaUrl}companies/${companyId}/workplace-categories`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_WORKPLACE_CATEGORIES));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      dispatch(actions.receiveError(error, types.GET_WORKPLACE_CATEGORIES));
    });
}