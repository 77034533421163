import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { Audio } from "react-loader-spinner";
import Table from "../../../components/Table";
import Tag from "../../../components/Tag";
import ListCard from "../../../components/ListCard";
import { locationFilterTypes } from "../../../locationHelpers";
import { colorGradient } from "../../../helpers";
import * as selectedActions from "../../../actions/selected";
import styled from "styled-components";

class Locations extends Component {

  constructor(props) {
    // console.log("Locations.constructor");
    super(props);
    this.state = {
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 100,
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
  }

  onRowClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {

          let link = `/companies/${this.props.match.params.companyId}/locations/${row.original._id}/locations/`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id]) {
      this.props.deselectLocation(row.original._id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }

  onToggleAll(locations) {
    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);
    if (allLocationsAreSelected) {
      this.props.deselectAllLocations();
    }
    else {
      this.props.selectLocations(locations);
    }
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  onOffsetChange(offset) {
    this.setState({ offset });
  }

  getTag(id) {
    const tag = this.props.customTags.find(tag => tag.id === id);
    if (tag) {
      return <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />
    }
    return null;
  }

  render() {
    // console.log("FrontPage.Locations.state", this.state);
    // console.log("FrontPage.Locations.props", this.props);

    const lastMonth = moment().subtract(1, "month");

    // Add properties to easier sort columns
    const loadingQuery = this.props.loadingQueries[this.props.queryHash];
    let locations = this.props.completedQueries[this.props.queryHash]?.locations ?? [];
    locations = locations.map(location => ({ ...location, typeName: get(locationFilterTypes.find(l => l.id === location.type), "name", location.type) }));
    locations = locations.map(location => {
      let tagString = "";
      if (location.customTags) {
        const norway = "nb-NO";
        tagString = location.customTags
          .map(tagId => this.props.customTags.find(tag => tag.id === tagId))
          .filter(n => n)
          .map(tag => tag.name)
          .sort((a, b) => a.localeCompare(b, norway))
          .join(", ");
      }

      return {
        ...location,
        tagString
      };
    });

    let sampleKey = null;
    if (this.props.displayType === "occupancy") {
      sampleKey = "occupiedCompareDayOfWeek";
    }
    else if (this.props.displayType === "people") {
      sampleKey = "peopleCompareDayOfWeek";
    }
    else if (this.props.displayType === "highcount" || this.props.displayType === "lowcount") {
      sampleKey = "maxOccupiedDayOfWeek";
    }

    let samples = [];
    let errorType = null;
    let errorMessage = null;
    if (sampleKey) {
      const queryHashKey = `${sampleKey}-${this.props.queryHash}`;
      samples = get(this.props, `dashboardData[${queryHashKey}]`, []);
      errorType = get(this.props, `dataLoadingStatus[${queryHashKey}].type`, null);
      errorMessage = get(this.props, `dataLoadingStatus[${queryHashKey}].message`, null);
    }

    // Hide rows with no data
    if (this.props.displayType !== "all") {
      locations = locations.filter(location => samples.find(sample => sample.entityId === location._id));
    }

    // Add week day columns for display of occupancy
    let weekDays = [];
    if (samples.length > 0) {
      if (this.state.showWeekends) {
        weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      }
      else {
        weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri"];
      }

      if (this.props.displayType === "highcount" || this.props.displayType === "lowcount") {
        weekDays.push("All days");
      }
    }

    if (this.state.sortBy && this.state.sortOrder) {
      locations = locations.sort((a, b) => {
        const norway = "nb-NO";
        if (this.state.sortBy === "name") {
          return this.state.sortOrder === "asc" ? a.name.localeCompare(b.name, norway) : b.name.localeCompare(a.name, norway);
        }
        else if (this.state.sortBy === "customTags") {
          // Sort by tagString with empty tags last
          if (a.tagString === "" && b.tagString !== "") {
            return this.state.sortOrder === "asc" ? 1 : -1;
          }
          else if (a.tagString !== "" && b.tagString === "") {
            return this.state.sortOrder === "asc" ? -1 : 1;
          }
          return this.state.sortOrder === "asc" ? a.tagString.localeCompare(b.tagString, norway) : b.tagString.localeCompare(a.tagString, norway);
        }
        else if (this.state.sortBy === "type") {
          return this.state.sortOrder === "asc" ? a.typeName.localeCompare(b.typeName) : b.typeName.localeCompare(a.typeName);
        }
        else if (this.state.sortBy.includes("occupied")) {
          const sortedDayOfWeek = this.state.sortBy.split("-")[1];

          if (["occupancy", "people"].includes(this.props.displayType)) {
            const sampleA = samples.find(sample => sample.entityId === a._id && `${sample.weekday}` === `${sortedDayOfWeek}`);
            const sampleB = samples.find(sample => sample.entityId === b._id && `${sample.weekday}` === `${sortedDayOfWeek}`);

            if (this.state.sortOrder === "asc") {
              const maxA = get(sampleA, "max", 1000);
              const maxB = get(sampleB, "max", 1000);
              return (maxA > maxB ? 1 : -1);
            }
            else {
              const maxA = get(sampleA, "max", -1);
              const maxB = get(sampleB, "max", -1);
              return (maxA < maxB ? 1 : -1);
            }
          }
          else if (["highcount"].includes(this.props.displayType)) {
            const sampleA = samples.find(sample => sample.entityId === a._id);
            const sampleB = samples.find(sample => sample.entityId === b._id);

            if (this.state.sortOrder === "asc") {
              const maxA = get(sampleA, `above[${sortedDayOfWeek}]`, 1000);
              const maxB = get(sampleB, `above[${sortedDayOfWeek}]`, 1000);
              return (maxA > maxB ? 1 : -1);
            }
            else {
              const maxA = get(sampleA, `above[${sortedDayOfWeek}]`, -1);
              const maxB = get(sampleB, `above[${sortedDayOfWeek}]`, -1);
              return (maxA < maxB ? 1 : -1);
            }
          }
          else if (["lowcount"].includes(this.props.displayType)) {
            const sampleA = samples.find(sample => sample.entityId === a._id);
            const sampleB = samples.find(sample => sample.entityId === b._id);

            if (this.state.sortOrder === "asc") {
              const maxA = get(sampleA, `below[${sortedDayOfWeek}]`, 1000);
              const maxB = get(sampleB, `below[${sortedDayOfWeek}]`, 1000);
              return (maxA > maxB ? 1 : -1);
            }
            else {
              const maxA = get(sampleA, `below[${sortedDayOfWeek}]`, -1);
              const maxB = get(sampleB, `below[${sortedDayOfWeek}]`, -1);
              return (maxA < maxB ? 1 : -1);
            }
          }
        }
        return false;
      });
    }

    const weekDayColumns = weekDays.map((day, index) => (
      {
        header: day,
        accessorKey: weekDays.length === index + 1 ? `occupied-all` : `occupied-${index + 1}`,
        minWidth: 50,
        maxWidth: 80,
        cell: ({ row }) => {

          let color1 = { red: 19, green: 233, blue: 19 };
          let color2 = { red: 255, green: 255, blue: 0 };
          let color3 = { red: 255, green: 0, blue: 0 };
          let color;
          let text;

          if (["occupancy", "people"].includes(this.props.displayType)) {
            let sample = samples.find(sample => sample.entityId === row.original._id && sample.weekday === index + 1);

            if (sample) {
              if (this.props.displayType === "occupancy") {
                color = colorGradient(sample.max / 100, color1, color2, color3, 0.6);
                text = `${sample.max} %`;
              }
              else {
                let location = locations.find(location => location._id === row.original._id);
                let capacity = get(location, `capacity.soft`, get(location, `capacity.hard`, null));
                if (capacity) {
                  color = colorGradient(sample.max / capacity, color1, color2, color3, 0.6);
                }
                text = `${sample.max}`;
              }
            }
          }
          else if (["highcount"].includes(this.props.displayType)) {
            let sample = samples.find(sample => sample.entityId === row.original._id);

            if (!sample) {
              return null;
            }

            // If last index show "sum of all days"
            if (index === weekDays.length - 1) {
              const sum = get(sample, `above.all`, 0);
              text = sum;
              color = colorGradient(sum / (4 * (weekDays.length - 1)), color1, color2, color3, 0.6);
            }
            else {
              const sampleValue = get(sample, `above.[${index + 1}]`, -1);

              if (sampleValue < 0) {
                return null;
              }

              text = sampleValue;
              color = colorGradient(sampleValue / 4, color1, color2, color3, 0.6);
            }
          }
          else if (["lowcount"].includes(this.props.displayType)) {
            let sample = samples.find(sample => sample.entityId === row.original._id);

            if (!sample) {
              return null;
            }

            // If last index show "sum of all days"
            if (index === weekDays.length - 1) {
              const sum = get(sample, `below.all`, 0);
              text = sum;
              color = colorGradient(sum / (4 * (weekDays.length - 1)), color1, color2, color3, 0.6);
            }
            else {
              const sampleValue = get(sample, `below.[${index + 1}]`, -1);

              if (sampleValue < 0) {
                return null;
              }

              text = sampleValue;
              color = colorGradient(sampleValue / 4, color1, color2, color3, 0.6);
            }
          }

          return (
            <div style={{ backgroundColor: color, textAlign: "center" }}>
              <span title={text}>{text}</span>
            </div>
          );

        },
        style: {
          margin: 0,
          padding: 0
        }
      }
    ));

    let allLocationsColumns = [];
    if (this.props.displayType === "all") {
      allLocationsColumns = [{
        header: "Tags",
        accessorKey: "customTags",
        minWidth: 100,
        cell: ({ row }) => {
          if (row.original["customTags"]) {
            const norway = "nb-NO";
            return row.original["customTags"]
              .map(tagId => this.props.customTags.find(tag => tag.id === tagId))
              .filter(n => n)
              .sort((a, b) => a.name.localeCompare(b.name, norway))
              .map(tag => <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />);
          }
          else {
            return null;
          }
        }
      }];
    }

    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);

    // Limit locations based on offset and limit
    const pagedLocations = locations.slice(this.state.offset, this.state.offset + this.state.limit);

    const tableElement = (
      <Table
        data={pagedLocations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={locations.length}
        onSortedChange={this.onSortedChange}
        onOffsetChange={this.onOffsetChange}
        noDataText={"No locations found"}
        columns={[
          {
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            header: () => (
              <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
                <input
                  id={`editCheckbox-header`}
                  type="checkbox"
                  className="checkbox"
                  checked={allLocationsAreSelected}
                  onChange={() => this.onToggleAll(locations)}
                />
                <span className="checkmark" />
              </label>
            ),
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id])}
                  onChange={() => this.onToggle(row)}
                />
                <span className="checkmark" />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            minWidth: 100,
            // maxWidth: 700,
            cell: ({ row }) => {
              let breadcrumbs = this.props.locationBreadcrumbs[row.original._id].map(breadcrumb => breadcrumb.name);
              breadcrumbs.pop();
              breadcrumbs = breadcrumbs.join(", ");
              if (breadcrumbs) {
                return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({breadcrumbs})</span></>)
              }
              return (<span title={row.original.name}>{row.original.name}</span>)
            }
          },
          {
            header: "Type",
            accessorKey: "type",
            minWidth: 50,
            maxWidth: 200,
            cell: ({ row }) => {
              const locationType = locationFilterTypes.find(locationType => locationType.id === row.original.type);
              const name = get(locationType, "name", row.original.type);
              return <span title={name}>{name}</span>
            }
          },
          ...allLocationsColumns,
          ...weekDayColumns,
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
      />
    );

    let title;
    let description;
    if (this.props.displayType === "occupancy") {
      title = "Occupancy peaks";
      description = `The occupancy on the busiest weekdays in ${lastMonth.format("MMMM")} based on quarter hour data. Showing locations with occupancy data, but not personal workstations (to preserve employee privacy).`;
    }
    else if (this.props.displayType === "people") {
      title = "People peaks";
      description = `The peak amount of people each weekday in ${lastMonth.format("MMMM")}. Showing locations with people count data.`;
    }
    else if (this.props.displayType === "highcount") {
      title = "Busy days";
      description = `The number of days with occupancy above 80% in ${lastMonth.format("MMMM")}. Showing locations with occupancy data, but not personal workstations (to preserve employee privacy).`;
    }
    else if (this.props.displayType === "lowcount") {
      title = "Quiet days";
      description = `The number of days with occupancy below 20% in ${lastMonth.format("MMMM")}. Showing locations with occupancy data, but not personal workstations (to preserve employee privacy).`;
    }
    else {
      title = "Locations";
      description = `All locations that matches the current search.`;
    }

    let content = null;
    if (isEmpty(loadingQuery) || this.props.isLoadingQuery || errorType === "loading" || loadingQuery === "loading") {
      content = (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "150px" }}>
          <Audio color="#ccc" height={"75px"} width={"75px"} />
        </div>
      );
    }
    else if (errorMessage) {
      content = (
        <BlockInnerStatusMessage>
          Warning: {errorMessage}
        </BlockInnerStatusMessage>
      );
    }
    else {
      content = tableElement;
    }

    let displayTypes = [];
    let color = "#1c4d82";
    displayTypes.push({ id: "all", name: "Overview", color: color });
    displayTypes.push({ id: "occupancy", name: "Occupancy peaks", color: color });
    displayTypes.push({ id: "people", name: "People peaks", color: color });
    displayTypes.push({ id: "highcount", name: "Busy days", color: color });
    displayTypes.push({ id: "lowcount", name: "Quiet days", color: color });

    const menuButtons = displayTypes.map((type) => (
      <MenuButton
        key={type.id}
        onClick={() => this.props.onDisplayTypeChanged(type.id)}
        $isSelected={this.props.displayType === type.id}
        $selectedColor={type.color}
        $color={"#333"}>
        <span style={{ userSelect: "none" }}>{type.name}</span>
      </MenuButton>
    ));

    const menu = (
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", columnGap: "10px", flexWrap: "wrap", marginBottom: "20px" }}>
        { menuButtons }
      </div>
    );

    return (
      <Container>
        { menu }
        <ListCard>
          <div style={{ display: "block", margin: "20px", boxSizing: "border-box" }}>
            <BlockInnerTitle>{title}</BlockInnerTitle>
            <BlockInnerDescription>{description}</BlockInnerDescription>
            {content}
          </div>
        </ListCard>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingQueries: state.locationQuery.loadingQueries,
    completedQueries: state.locationQuery.completedQueries,

    locationHierarchy: state.locationQuery.hierarchy,
    flatHierarchy: state.locationQuery.flatHierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    dashboardData: state.dashboards.data,
    dataLoadingStatus: state.dashboards.dataLoadingStatus,
    customTags: state.customTags.list,
    isLoadingQuery: state.locationQuery.isLoadingQuery,
    selectedLocations: state.selected.locations
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectLocation: selectedActions.selectLocation,
    selectLocations: selectedActions.selectLocations,
    deselectLocation: selectedActions.deselectLocation,
    deselectAllLocations: selectedActions.deselectAllLocations,
    clearSelection: selectedActions.clearSelection,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations);

// Status bar with the same width as the tableau
const Container = styled.div`
  width: 100%;
  max-width: 1450px;
`;

const MenuButton = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => props.$isSelected ? (props.$selectedColor ?? "#1c4d82") : "#fff"};
  border-color: ${(props) =>props.$isSelected ? (props.$selectedColor ?? "#1c4d82") : (props.$color ?? "#1c4d82")};
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding: 8px 15px;

  span {
    color: ${(props) => props.$isSelected ? "#fff" : (props.$color ?? "#1c4d82")};
    font-weight: 600;
    font-size: 15px;
  }
`;

const BlockInnerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const BlockInnerDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  margin-bottom: 5px;
`;

const BlockInnerStatusMessage = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: #222; 
  margin-top: 20px;
  margin-bottom: 20px;
`;

