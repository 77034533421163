import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import AnimateHeight from "react-animate-height";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import Caret from "./AppUsageCard/caret3_grey.png";
import Table from "../../components/Table";
import { OuterContainer, InnerContainer, Row, Col } from ".";
import styled from "styled-components";
import atomicSensor from "../Sensor/atomicSensor";

class Integrations extends Component {

  constructor(props) {
    // console.log("Integrations.constructor");
    super(props);
    this.state = {

    };
  }

  render() {

    let inboundBlock = this.props.inboundIntegrations.map((integration, index) => {

      let differenceIcon;
      let valueChange = integration.atomicSensorCurrentMonth - integration.atomicSensorPreviousMonth;
      if (valueChange > 0) {
        differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }
      else if (valueChange < 0) {
        differenceIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
      }
      else if (valueChange === 0) {
        differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }

      return (
        <Row key={`inbound-integration-${index}`}>
          <Col size="lg" $noMargin>
            <BigBlock>
              <BlockContent>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <BlockInnerTitle>{ integration.name }</BlockInnerTitle>
                  
                  <BlockStatisticsContainer style={{ width: "100px", flexDirection: "column", justifyContent: "center", padding: "12px 10px" }}>
                    <HeaderValueContainer>
                      <HeaderValue>
                        { integration.atomicSensorCurrentMonth }
                      </HeaderValue>
                      <ValueChange>
                        ({ differenceIcon }{ Math.abs(valueChange) })
                      </ValueChange>
                    </HeaderValueContainer>
                    <div style={{ display: "inline-block", fontSize: "14px", color: "#555" }}>atomic sensors</div>
                  </BlockStatisticsContainer>

                </div>
              </BlockContent>
            </BigBlock>
          </Col>
        </Row>
      );
    });

    let outboundBlock = this.props.outboundIntegrations.map((integration, index) => {

      let differenceIcon;
      let valueChange = integration.atomicSensorCurrentMonth - integration.atomicSensorPreviousMonth;
      if (valueChange > 0) {
        differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }
      else if (valueChange < 0) {
        differenceIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
      }
      else if (valueChange === 0) {
        differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }

      return (
        <Row key={`outbound-integration-${index}`}>
          <Col size="lg" $noMargin>
            <BigBlock>
              <BlockContent>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <BlockInnerTitle>{ integration.type }<BreadCrumb>({ integration.destination })</BreadCrumb></BlockInnerTitle>
                  
                  <BlockStatisticsContainer style={{ width: "100px", flexDirection: "column", justifyContent: "center", padding: "12px 10px" }}>
                    <HeaderValueContainer>
                      <HeaderValue>
                        { integration.atomicSensorCurrentMonth }
                      </HeaderValue>
                      <ValueChange>
                        ({ differenceIcon }{ Math.abs(valueChange) })
                      </ValueChange>
                    </HeaderValueContainer>
                    <div style={{ display: "inline-block", fontSize: "14px", color: "#555" }}>atomic sensors</div>
                  </BlockStatisticsContainer>

                </div>
              </BlockContent>
            </BigBlock>
          </Col>
        </Row>
      );
    });

    // String of last month in english
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth = lastMonth.toLocaleString("en-us", { month: "long" });
    lastMonth = lastMonth.toLowerCase();

    return (
      <OuterContainer $darkBackground>
        <InnerContainer>
          <Row $paddingTop="15px">
            <Col>
              <h2>Inbound data streams<BreadCrumb>({ lastMonth })</BreadCrumb></h2>
              <p>The number of atomic sensors (individual sensor points) that get data from 3. party services and the change from the previous month.</p>
            </Col>
          </Row>
          { inboundBlock }
          <Row $paddingTop="15px">
            <Col>
              <h2>Outbound data streams<BreadCrumb>({ lastMonth })</BreadCrumb></h2>
              <p>The number of atomic sensors (individual sensor points) that send data to 3. party services and the change from the previous month.</p>
            </Col>
          </Row>
          { outboundBlock }
        </InnerContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    inboundIntegrations: state.accountSummary.inboundIntegrations,
    outboundIntegrations: state.accountSummary.outboundIntegrations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Integrations));

const BigBlock = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  overflow: hidden;
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  // padding: 15px 20px 15px 20px;
  font-size: 16px;
  font-weight: 400;
`;

const BlockInnerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px 15px 20px;

  font-size: 20px;
  font-weight: 600;
  color: #222222;

  div { 
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }

  div:last-child {
    margin-left: 5px;
    
    font-size: 15px;
    font-weight: 600;
    color: #666666;

  }
`;

const BreadCrumb = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #666;
  // margin-bottom: 5px;
  margin-left: 5px;
`;

const BlockStatisticsContainer = styled.div`
  cursor: pointer;
  font-size: 16px;
  color: #222222;
  padding-right: 20px;
  width: ${props => props.width || "auto"};
  
  // align text in the middle
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const HeaderValueContainer = styled.div`
`;

const HeaderValue = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 21px;
  line-height: 21px;
  font-weight: 600;
  color: #222222;
`;

const ValueChange = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 600;
  color: #888888;
  margin-left: 4px;
  line-height: 20px
`;