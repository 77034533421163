import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import KPIBox from "./kpiBox";
import { WidgetValueType } from "./types";
import styled from "styled-components";
import KPIBox2 from "./kpiBox2";

interface HeatMapProps {
  data: any[];
  valueType: WidgetValueType;
  description: string;
  showChangeBar?: boolean;
  showMaxLine?: boolean;
}

const HeatMap: React.FC<HeatMapProps> = ({ data, valueType, description }) => {

  const columns = data.map((item, index) => {
    console.log("item", item);
    const value = item.value;
    const change = item.change ?? 0;
    const total = item.total ?? 100;
    
    if (value === null || value < 0) {
      return null;
    }

    // Threshold at 20% of total
    const threshold = total * 0.2;

    if (valueType === WidgetValueType.Percentage) {
      return (
        <Column key={index}>
          {/* <KPIBox
            targetValue={value}
            targetChange={item.percentageChange ?? 0}
            thresholdValue={threshold}
            zeroColor="#acacac"//"#3d86d5"
            minColor="#E26F53"//"#79AAE2"
            maxColor="#DA3535"//#2564A7"
            duration={1000}
          /> */}
          <KPIBox
            targetValue={value}
            targetChange={item.percentageChange}
            thresholdValue={threshold}
            zeroColor="#acacac"//"#3d86d5"
            minColor="#3d86d5"
            maxColor="#1c4d82"
            duration={1000}
          />
          <Description>{item.description}</Description>
        </Column>
      );
    }

    return (
      <Column key={index}>
        {/* <KPIBox2
          targetValue={value}
          targetChange={item.change ?? 0}
          thresholdValue={threshold}
          zeroColor="#acacac"//"#3d86d5"
          minColor="#E26F53"//"#79AAE2"
          maxColor="#DA3535"//#2564A7"
          duration={1000}
        /> */}
        <KPIBox2
          targetValue={value}
          targetChange={item.change}
          thresholdValue={threshold}
          zeroColor="#acacac"//"#3d86d5"
          minColor="#3d86d5"
          maxColor="#1c4d82"
          duration={1000}
        />
        <Description>{item.description}</Description>
      </Column>
    );
  });

  return (
    <Container>
      {columns}
      <GraphDescription>{description}</GraphDescription>
    </Container>
  );
};

export default HeatMap;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70px;
  margin-right: 5px;
  margin-bottom: 10px;
  flex-shrink: 1;
  min-width: 80px;
  max-width: 110px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  font-size: 12px;
  color: #555;
  text-align: center;
  height: 16px;
`;

const GraphDescription = styled.div`
  font-size: 12px;
  color: #555;
  margin-top: 27px;
  // width: 60px;
  white-space: pre-line;
`;