import get from "lodash/get";
import moment from "moment";
import { WidgetGraphType, WidgetValueType } from "./Widget/types";

// const WPAColors = {
//   COLLABORATION_ROOMS: "#1C8082",
//   SUPPORTING_ROOMS: "#8f2d56",
//   ACTIVITY_ZONE: "#624444",
//   PEOPLE_COUNT: "#1c4d82",
//   WORKSTATIONS: "#542344"
// };

export const WPAColors = {
  OVERVIEW: "#111",
  COLLABORATION_ROOMS: "#17786b",
  SUPPORTING_ROOMS: "#9C315E",
  ACTIVITY_ZONE: "#1A8793",
  PEOPLE_COUNT: "#14628F",
  WORKSTATIONS: "#613286"
};

export const WPAColorsDark = {
  OVERVIEW: "#111",
  COLLABORATION_ROOMS: "#344b47",
  SUPPORTING_ROOMS: "#67213E",
  ACTIVITY_ZONE: "#156F79",
  PEOPLE_COUNT: "#0F496B",
  WORKSTATIONS: "#41215A"
};

export const getWidgets = (locationName, widgetProfile, page) => {
  // console.log("getWidgets", locationName, widgetProfile, page);

  const startDate = moment.utc().subtract(1, "month").startOf("month");
  const endDate = moment.utc().subtract(1, "month").endOf("month");
  const lastMonth = moment.utc().subtract(1, "month").startOf("month");
  const lastLastMonth = moment.utc().subtract(2, "month").startOf("month");

  const monthString = lastMonth.format("MMMM");
  const lastMonthString = lastLastMonth.format("MMMM");
  const monthDailyCount = endDate.diff(startDate, "days") + 1;

  // Make array from 1 to monthDailyCount
  const monthDailyArray = Array.from({ length: monthDailyCount }, (v, k) => k + 1);

  // Get the color based on the category name
  let mainColor = "#333";
  let darkColor = "#999";
  let resourceName = "rooms";
  let singleResourceName = "room";
  if (page.id === "peopleCount") {
    mainColor = WPAColors.PEOPLE_COUNT;
    darkColor = WPAColorsDark.PEOPLE_COUNT;
    resourceName = "locations";
    singleResourceName = "location";
  }
  else if (page.name === "CollaborationSpace" || page.name === "Meeting Rooms") {
    mainColor = WPAColors.COLLABORATION_ROOMS;
    darkColor = WPAColorsDark.COLLABORATION_ROOMS;
  }
  else if (page.name === "SupportingSpace" || page.name === "Quiet Rooms") {
    mainColor = WPAColors.SUPPORTING_ROOMS;
    darkColor = WPAColorsDark.SUPPORTING_ROOMS;
  }
  else if (page.name === "ActivityZone") {
    mainColor = WPAColors.ACTIVITY_ZONE;
    darkColor = WPAColorsDark.ACTIVITY_ZONE;
    resourceName = "zones";
    singleResourceName = "zone";
  }
  else if (page.name === "Workstations") {
    mainColor = WPAColors.WORKSTATIONS;
    darkColor = WPAColorsDark.WORKSTATIONS;
    resourceName = "workstations";
    singleResourceName = "workstation";
  }
  else if (page.id === "overview") {
    mainColor = WPAColors.OVERVIEW;
    darkColor = WPAColorsDark.OVERVIEW;
  }

  // When in Overview - return predefined widgets
  if (page.id === "overview") {
    return get(widgetProfile, "overview.widgets", []);
  }
  else if (page.id === "peopleCount") {
    const customTagId = get(widgetProfile, "peopleCount.customTagId", undefined);
    return [
      {
        id: "peopleCountIntraday",
        customTagId: customTagId,
        wpaCategoryNames: ["People count"],
        type: WidgetGraphType.VerticalBar,
        valueType: WidgetValueType.Count,
        dateKey: "hour",
        title: `Daily people count`,
        subtitle: lastMonth.format("MMMM YYYY"),
        description: `The highest number of people by hour of day in ${locationName}.`,
        dataLayout: [
          { property: "peakPeak", hour: 7, color: mainColor, description: "07-08" },
          { property: "peakPeak", hour: 8, color: mainColor, description: "" },
          { property: "peakPeak", hour: 9, color: mainColor, description: "" },
          { property: "peakPeak", hour: 10, color: mainColor, description: "" },
          { property: "peakPeak", hour: 11, color: mainColor, description: "" },
          { property: "peakPeak", hour: 12, color: mainColor, description: "" },
          { property: "peakPeak", hour: 13, color: mainColor, description: "" },
          { property: "peakPeak", hour: 14, color: mainColor, description: "" },
          { property: "peakPeak", hour: 15, color: mainColor, description: "" },
          { property: "peakPeak", hour: 16, color: mainColor, description: "16-17" }
        ]
      },
      {
        id: "peopleCountWeekday",
        customTagId: customTagId,
        type: WidgetGraphType.VerticalBar,
        valueType: WidgetValueType.Count,
        dateKey: "weekday",
        title: `Weekly people count`,
        subtitle: lastMonth.format("MMMM YYYY"),
        description: `The highest number of people in by day of the week in ${locationName}.`,
        dataLayout: [
          { property: "peakPeak", weekday: 1, color: mainColor, description: "Monday" },
          { property: "peakPeak", weekday: 2, color: mainColor, description: "" },
          { property: "peakPeak", weekday: 3, color: mainColor, description: "" },
          { property: "peakPeak", weekday: 4, color: mainColor, description: "" },
          { property: "peakPeak", weekday: 5, color: mainColor, description: "Friday" },
        ]
      },
      {
        id: "peopleCountMonth",
        customTagId: customTagId,
        type: WidgetGraphType.HorizontalBar,
        valueType: WidgetValueType.Count,
        title: `Monthly people count`,
        subtitle: lastMonth.format("MMMM YYYY"),
        description: `The highest number of people in ${locationName}.`,
        dataLayout: [
          { property: "peakPeak", color: mainColor, description: "at peak" },
          { property: "avgPeak", color: darkColor, description: "average daily peak" },
        ]
      },
      {
        id: "atCapacityMinutes",
        customTagId: customTagId,
        type: WidgetGraphType.MonthlyDurationAtCapacity,
        valueType: WidgetValueType.Minutes,
        title: `Duration at capacity`,
        subtitle: `${lastMonth.format("MMMM YYYY")}`,
        resourceName: resourceName,
        singleResourceName: singleResourceName,
        description: `The total duration ${locationName} were at 100% capacity.`,
        dataLayout: [
          { property: "peak", description: lastMonthString, color: mainColor },
          { property: "peak", description: monthString, color: darkColor }
        ]
      }
    ];
  }

  let widgets = [
    {
      id: "utilizationDistributionMonth",
      type: WidgetGraphType.HorizontalCompareMonths,
      valueType: WidgetValueType.Count,
      dateKey: "datetime",
      title: `Unused ${resourceName}`,
      subtitle: `${lastMonth.format("MMMM YYYY")}`,
      resourceName: resourceName,
      singleResourceName: singleResourceName,
      description: `The number of ${resourceName} that had peak occupancy above 80% at least one workday in ${locationName}.`,
      dataLayout: [
        { property: "combinedDistribution[0].count", change: "combinedDistribution[0].countChange", datetime: lastMonth.toISOString(), color: mainColor, description: "0-10%" },
        { property: "combinedDistribution[1].count", change: "combinedDistribution[1].countChange", datetime: lastMonth.toISOString(), color: darkColor, description: "10-20%" }
      ],
    },
    {
      id: "utilizationDistributionMonth",
      type: WidgetGraphType.MonthlyUtilizationDistribtion,
      valueType: WidgetValueType.Count,
      dateKey: "datetime",
      title: `Occupancy distribution`,
      subtitle: lastMonth.format("MMMM YYYY"),
      resourceName: resourceName,
      singleResourceName: singleResourceName,
      dataLayout: [
        { property: "combinedDistribution[0].count", change: "combinedDistribution[0].change", datetime: lastMonth.toISOString(), color: mainColor, description: "0-20%" },
        { property: "combinedDistribution[1].count", change: "combinedDistribution[1].change", datetime: lastMonth.toISOString(), color: mainColor, description: "20-40%" },
        { property: "combinedDistribution[2].count", change: "combinedDistribution[2].change", datetime: lastMonth.toISOString(), color: mainColor, description: "40-60%" },
        { property: "combinedDistribution[3].count", change: "combinedDistribution[3].change", datetime: lastMonth.toISOString(), color: mainColor, description: "60-80%" },
        { property: "combinedDistribution[4].count", change: "combinedDistribution[4].change", datetime: lastMonth.toISOString(), color: mainColor, description: "80-100%" },
      ],
    },
    {
      id: "utilizationMonthTopLocations",
      wpaCategoryNames: ["CollaborationSpace", "Meeting Rooms"],
      type: WidgetGraphType.DynamicValueList,
      valueType: WidgetValueType.Percentage,
      dateKey: "datetime",
      title: `Most popular ${resourceName}`,
      subtitle: lastMonth.format("MMMM YYYY"),
      description: `The ${resourceName} with the highest occupancy in ${locationName}.`,
      dataLayout: { property: "avgOccupancy", datetime: lastMonth.toISOString(), color: mainColor, nameProperty: "locationName" }
    },
    {
      id: "utilizationMonthBottomLocations",
      type: WidgetGraphType.DynamicValueList,
      valueType: WidgetValueType.Percentage,
      dateKey: "datetime",
      title: `Least popular ${resourceName}`,
      subtitle: lastMonth.format("MMMM YYYY"),
      description: `The ${resourceName} with the lowest occupancy in ${locationName}.`,
      dataLayout: { property: "avgOccupancy", datetime: lastMonth.toISOString(), color: mainColor, nameProperty: "locationName" }
    },
    {
      id: "utilizationWeekday",
      type: WidgetGraphType.VerticalBar,
      valueType: WidgetValueType.Percentage,
      dateKey: "weekday",
      title: `Weekly peak utilization`,
      subtitle: lastMonth.format("MMMM YYYY"),
      description: `The highest utilization reached each weekday in ${locationName}.`,
      dataLayout: [
        { property: "peakPeak", weekday: 1, color: mainColor, description: "Monday" },
        { property: "peakPeak", weekday: 2, color: mainColor, description: "" },
        { property: "peakPeak", weekday: 3, color: mainColor, description: "" },
        { property: "peakPeak", weekday: 4, color: mainColor, description: "" },
        { property: "peakPeak", weekday: 5, color: mainColor, description: "Friday" },
      ],
    },
    {
      id: "utilizationMonth",
      type: WidgetGraphType.HorizontalBar,
      valueType: WidgetValueType.Percentage,
      title: `Monthly peak and average utilization`,
      subtitle: lastMonth.format("MMMM YYYY"),
      description: `The highest and average utilization in ${locationName}.`,
      resourceName: resourceName,
      singleResourceName: singleResourceName,
      dataLayout: [
        { property: "peakPeak", color: mainColor, description: "at peak" },
        { property: "avgPeak", color: darkColor, description: "average daily peak" },
      ]
    },
    {
      id: "utilizationDay",
      type: WidgetGraphType.UtilizationSparklineMonth,
      valueType: WidgetValueType.Percentage,
      title: `Monthly peak utilization`,
      subtitle: lastMonth.format("MMMM YYYY"),
      color: mainColor,
      dataLayout: monthDailyArray.map((_, index) => ({ property: "peakPeak", description: index === 0 ? startDate.format("D. MMMM") : (index === monthDailyArray.length - 1 ? endDate.format("D. MMMM") : "") })),
    }
  ];

  if (page.name !== "ActivityZone") {
    widgets.push(
      {
        id: "notUtilizedMinutes",
        type: WidgetGraphType.MonthlyDurationEmpty,
        valueType: WidgetValueType.Minutes,
        title: "Everything available",
        subtitle: `${lastMonth.format("MMMM YYYY")}`,
        resourceName: resourceName,
        singleResourceName: singleResourceName,
        dataLayout: [
          { property: "peak", description: lastMonthString, color: mainColor },
          { property: "peak", description: monthString, color: darkColor }
        ],
      },
      {
        id: "fullyUtilizedMinutes",
        type: WidgetGraphType.MonthlyDurationFull,
        valueType: WidgetValueType.Minutes,
        title: "Nothing available",
        subtitle: `${lastMonth.format("MMMM YYYY")}`,
        resourceName: resourceName,
        singleResourceName: singleResourceName,
        dataLayout: [
          { property: "peak", description: lastMonthString, color: mainColor },
          { property: "peak", description: monthString, color: darkColor }
        ]
      }
    );
  }

  return widgets;
}