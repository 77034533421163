import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PercentageText from "./percentageText";
import AnimatedBar from "./animatedBar";
import { Popup, PopupAnchor, SourceAnchor } from "ui";
import { WidgetValueType } from "./types";

interface ValueListGraphProps {
  data: any[];
  valueType: WidgetValueType;
  max?: number;
  showMaxLine?: boolean;
  onClick?: (event: any, item: any) => void;
  hoverText?: string;
}

const ValueListGraph: React.FC<ValueListGraphProps> = ({ data, valueType, max, showMaxLine, onClick, hoverText }) => {

  // Store mouse position
  const [mouseMovePosition, setMouseMovePosition] = useState<{ x: number, y: number } | null>(null);

  // Update mouse position
  const handleMouseMove = (event: any) => {
    setMouseMovePosition({ x: event.clientX, y: event.clientY });
  };

  // Clear mouse position when mouse leaves the area
  const handleMouseLeave = (event: any) => {
    setMouseMovePosition(null);
  }

  const maxValue = Math.max(...data.map(item => item.value));

  const rows = data.map((item, index) => {

    const hasClick = !!onClick;
    const handleClick = (event: any) => {
      if (hasClick) {
        onClick(event, item);
      }
    };
    
    let valueInPercentage = null;
    let value = null;
    let valueBoxWidth = 0;
    switch (valueType) {
      case WidgetValueType.Percentage:
        valueInPercentage = Math.round(item.value * 100 * 100) / 100;
        value = valueInPercentage;
        valueBoxWidth = 50;
        break;
      case WidgetValueType.Count:
        if (max) {
          valueInPercentage = Math.round((item.value / max) * 100 * 100) / 100;
        }
        else {
          valueInPercentage = maxValue > 0 ? (Math.round((item.value / maxValue) * 100 * 100) / 100) : 0;
        }
        value = item.value;

        if (maxValue > 999) {
          valueBoxWidth = 60;
        }
        else {
          valueBoxWidth = 50;
        }

        break;
      case WidgetValueType.Minutes:
        valueInPercentage = maxValue > 0 ? (Math.round((item.value / maxValue) * 100 * 100) / 100) : 0;
        value = item.value;
        valueBoxWidth = 60;
        break;
      default:
        break;
    }
    
    if (valueInPercentage === null || valueInPercentage < 0) {
      return null;
    }

    return (
      <Row key={index}>
        <NameContainer>
          <Name onClick={handleClick} $hasClick={hasClick} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
            { item.name }
          </Name>
        </NameContainer>
        <BarContainer onClick={handleClick} $hasClick={hasClick} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
          { showMaxLine && max && <MaxLine value={80} $showWhiteLine={valueInPercentage > 100} /> }
          <AnimatedBar isVertical={false} value={(showMaxLine && max) ? 80*valueInPercentage/100 : valueInPercentage} color={item.color} />
        </BarContainer>
        <ValueBox size={valueBoxWidth} onClick={handleClick} $hasClick={hasClick} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
          <PercentageText valueType={valueType} targetValue={value} duration={1000} />
        </ValueBox>
      </Row>
    );
  });

  return (
    <Container>
      { rows }
      { mouseMovePosition && hoverText && (
        <Popup 
          isVisible={!!mouseMovePosition}
          sourcePoint={mouseMovePosition}
          popupAnchor={PopupAnchor.TOP_LEFT}
          sourceAnchor={SourceAnchor.CENTER}
          style={{
            // height: "50px",
            // width: "200px",
            fontSize: "14px",
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            borderWidth: "1px",
            borderColor: "#ccc",
            borderStyle: "solid",
            padding: "5px 8px",
            margin: "10px",
            zIndex: 1000,
            boxShadow: "2px 2px 5px 0px #0004",
            pointerEvents: "none"
          }}
        >
          { hoverText }
        </Popup>
      )}
    </Container>
  );
};

export default ValueListGraph;


const Container = styled.div`
  display: block;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // flex-grow: 1;
  height: 100%;
  min-width: 200px;
`;

const Name = styled.div<{ $hasClick: boolean }>`
  font-size: 16px;
  color: #333;
  white-space: pre-line;

  cursor: ${props => props.$hasClick ? "pointer" : "default"}; // none
`;

const BarContainer = styled.div<{ $hasClick: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  height: 100%;
  background-color: #eee;
  border-radius: 8px;
  overflow: hidden;

  cursor: ${props => props.$hasClick ? "pointer" : "default"}; // none
`;

// Dotted line at the top of the bar
const MaxLine = styled.div<{ value: number, $showWhiteLine: boolean }>`
  position: absolute;
  left: ${props => props.value}%;
  width: 1px;
  height: 100%;
  border-radius: 8px;
  border-right: 1px dashed #999;
  border-right: ${props => props.$showWhiteLine ? "1px dashed #fff" : "1px dashed #999"};
`;

const ValueBox = styled.div<{ size: number, $hasClick: boolean }>`
  font-size: 14px;
  font-weight: bold;
  color: #666;
  background-color: #eee;
  border-radius: 8px;
  margin-left: 8px;
  width: ${props => props.size}px;
  height: 30px;
  text-align: center;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${props => props.$hasClick ? "pointer" : "default"}; // none
`;

const Description = styled.div`
  font-size: 12px;
  color: #555;
  margin-left: 8px;
  width: 60px;
  white-space: pre-line;
`;
