// Data transfer
export const REQ_DATA = "REQ_DATA";
export const RECV_DATA = "RECV_DATA";
export const RECV_ERROR = "RECV_ERROR";
export const POST_DATA = "POST_DATA";
export const CLEAR_DATA = "CLEAR_DATA";
export const INSPECT = "INSPECT";
export const SUCCESS = "SUCCESS";

// Authentication
export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const FETCH_MESSAGE = "FETCH_MESSAGE";
export const LOGGING_IN = "LOGGING_IN";
export const GET_PROFILE = "GET_PROFILE";
export const GET_ADMIN_COMPANIES = "GET_ADMIN_COMPANIES";
export const GET_SUDO_COMPANIES = "GET_SUDO_COMPANIES";
export const GET_SUDO_COMPANY = "GET_SUDO_COMPANY";
export const GET_COMPANY = "GET_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const UPDATE_COMPANY_DRAFT = "UPDATE_COMPANY_DRAFT";
export const CLEAR_COMPANY_DRAFT = "CLEAR_COMPANY_DRAFT";
export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const UPDATE_COMPANY_DETAILS = "UPDATE_COMPANY_DETAILS";
export const UPDATE_COMPANY_DETAILS_DRAFT = "UPDATE_COMPANY_DETAILS_DRAFT";
export const CLEAR_COMPANY_DETAILS_DRAFT = "CLEAR_COMPANY_DETAILS_DRAFT";
export const RESTART_YANZI_WORKER = "RESTART_YANZI_WORKER";
export const SYNC_YANZI = "SYNC_YANZI";
export const GET_WEB_APP_URL = "GET_WEB_APP_URL";

// Account
export const GET_SERVICE_ACCOUNTS = "GET_SERVICE_ACCOUNTS";
export const GET_SERVICE_ACCOUNT = "GET_SERVICE_ACCOUNT";
export const CREATE_SERVICE_ACCOUNT = "CREATE_SERVICE_ACCOUNT";
export const UPDATE_SERVICE_ACCOUNT = "UPDATE_SERVICE_ACCOUNT";
export const DELETE_SERVICE_ACCOUNT = "DELETE_SERVICE_ACCOUNT";
export const UPDATE_SERVICE_ACCOUNT_DRAFT = "UPDATE_SERVICE_ACCOUNT_DRAFT";
export const CLEAR_SERVICE_ACCOUNT_DRAFT = "CLEAR_SERVICE_ACCOUNT_DRAFT";
export const CLEAR_SERVICE_ACCOUNT = "CLEAR_SERVICE_ACCOUNT";
export const GET_ACCOUNT_SUMMARY = "GET_ACCOUNT_SUMMARY";
export const SEND_CONSENT_EMAIL = "SEND_CONSENT_EMAIL";
export const GET_CONSENT_LINK = "GET_CONSENT_LINK";

// Sensors
export const GET_SENSORS = "GET_SENSORS";
export const GET_ALL_SENSORS = "GET_ALL_SENSORS";
export const SET_SENSOR_SEARCH = "SET_SENSOR_SEARCH";
export const CLEAR_ALL_SENSORS = "CLEAR_ALL_SENSORS";
export const CREATE_SENSOR = "CREATE_SENSOR";
export const MOVE_SENSORS = "MOVE_SENSORS";
export const UPDATE_SENSOR = "UPDATE_SENSOR";
export const MOVE_SENSORS_TO_GATEWAY = "MOVE_SENSORS_TO_GATEWAY";
export const DELETE_SENSOR = "DELETE_SENSOR";
export const GET_SENSOR = "GET_SENSOR";
export const GET_SAMPLES = "GET_SAMPLES";
export const GET_DISCOVERED_SENSORS = "GET_DISCOVERED_SENSORS";
export const GET_WEBEX_MACRO = "GET_WEBEX_MACRO";
export const GET_VORTO_IDS = "GET_VORTO_IDS";
export const GET_SENSOR_TYPES = "GET_SENSOR_TYPES";
export const GET_SENSOR_UPTIME = "GET_SENSOR_UPTIME";
export const GET_UNREGISTERED_SENSORS = "GET_UNREGISTERED_SENSORS";
export const GET_UNREGISTERED_SENSOR = "GET_UNREGISTERED_SENSOR";
export const UPDATE_UNREGISTERED_SENSOR = "UPDATE_UNREGISTERED_SENSOR";
export const UPDATE_UNREGISTERED_SENSORS = "UPDATE_UNREGISTERED_SENSORS";
export const CREATE_UNREGISTERED_SENSORS = "CREATE_UNREGISTERED_SENSORS";
export const UPDATE_UNREGISTERED_SENSOR_DRAFTS = "UPDATE_UNREGISTERED_SENSOR_DRAFTS";
export const CLEAR_UNREGISTERED_SENSOR_DRAFTS = "CLEAR_UNREGISTERED_SENSOR_DRAFTS";
export const UPDATE_UNREGISTERED_SENSOR_DRAFT = "UPDATE_UNREGISTERED_SENSOR_DRAFT";
export const CLEAR_UNREGISTERED_SENSOR_DRAFT = "CLEAR_UNREGISTERED_SENSOR_DRAFT";
export const CLEAR_UNREGISTERED_SENSOR = "CLEAR_UNREGISTERED_SENSOR";
export const GET_UNREGISTERED_SENSOR_SAMPLES = "GET_UNREGISTERED_SENSOR_SAMPLES";
export const GET_OFFLINE_SENSORS = "GET_OFFLINE_SENSORS";
export const DOWNLOAD_SENSOR_CSV = "DOWNLOAD_SENSOR_CSV";
export const DELETE_SAMPLES = "DELETE_SAMPLES";

// Atomic sensors
export const UPDATE_ATOMIC_SENSOR = "UPDATE_ATOMIC_SENSOR";

// Mesh Nodes
export const CREATE_MESH_NODE = "CREATE_MESH_NODE";

// Gateways
export const GET_GATEWAYS = "GET_GATEWAYS";
export const GET_ALL_GATEWAYS = "GET_ALL_GATEWAYS";
export const SET_GATEWAY_SEARCH = "SET_GATEWAY_SEARCH";
export const CLEAR_ALL_GATEWAYS = "CLEAR_ALL_GATEWAYS";
export const FIND_GATEWAY = "FIND_GATEWAY";
export const GET_GATEWAY = "GET_GATEWAY";
export const CREATE_GATEWAY = "CREATE_GATEWAY";
export const MOVE_GATEWAYS = "MOVE_GATEWAYS";
export const UPDATE_GATEWAY = "UPDATE_GATEWAY";
export const GET_GATEWAY_SENSORS = "GET_GATEWAY_SENSORS";
export const GET_OFFLINE_GATEWAYS = "GET_OFFLINE_GATEWAYS";
export const DOWNLOAD_GATEWAY_CSV = "DOWNLOAD_GATEWAY_CSV";
export const GET_GATEWAY_UPTIME = "GET_GATEWAY_UPTIME";

// Locations
export const GET_ROOT_LOCATION = "GET_ROOT_LOCATION";
export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const QUERY_LOCATIONS = "QUERY_LOCATIONS";
export const SEARCH_LOCATIONS = "SEARCH_LOCATIONS";
export const ADD_LOCATION = "ADD_LOCATION";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const DELETE_LOCATIONS = "DELETE_LOCATIONS";
export const MOVE_LOCATIONS = "MOVE_LOCATIONS";
export const CHANGE_LOCATIONS_QUERY = "CHANGE_LOCATIONS_QUERY";
export const GET_LOCATION_HIERARCHY = "GET_LOCATION_HIERARCHY";
export const GET_LOCATION_CSV = "GET_LOCATION_CSV";
export const UPLOAD_LOCATION_CSV = "UPLOAD_LOCATION_CSV";
export const GENERATE_QR_CODES = "GENERATE_QR_CODES";
export const CLEAR_LOCATION = "CLEAR_LOCATION";
export const UPDATE_QUICK_SEARCH_TEXT = "UPDATE_QUICK_SEARCH_TEXT";
export const NEW_QUERY_LOCATIONS = "NEW_QUERY_LOCATIONS";
export const SET_LOCATION_SEARCH = "SET_LOCATION_SEARCH";

// Map in company
export const GET_COMPANY_MAP = "GET_COMPANY_MAP";
export const SYNC_COMPANY_MAP = "SYNC_COMPANY_MAP";
export const UPLOAD_COMPANY_MAP = "UPLOAD_COMPANY_MAP";
export const REGISTER_COMPANY_FEATURE = "REGISTER_COMPANY_FEATURE";
export const ACQUIRE_COMPANY_FEATURE = "ACQUIRE_COMPANY_FEATURE";

// Maps in locations
export const GET_FLOOR_MAP = "GET_FLOOR_MAP";
export const SYNC_FLOOR_MAP = "SYNC_FLOOR_MAP";
export const UPLOAD_FLOOR_MAP = "UPLOAD_FLOOR_MAP";
export const MAP_FEATURE_TO_LOCATION = "MAP_FEATURE_TO_LOCATION";
export const REMOVE_FEATURES_FROM_LOCATIONS = "REMOVE_FEATURES_FROM_LOCATIONS";

// Select
export const SELECT_COMPANY = "SELECT_COMPANY";
export const DESELECT_COMPANY = "DESELECT_COMPANY";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const DESELECT_LOCATION = "DESELECT_LOCATION";
export const SELECT_LOCATIONS = "SELECT_LOCATIONS";
export const DESELECT_LOCATIONS = "DESELECT_LOCATIONS";
export const DESELECT_ALL_LOCATIONS = "DESELECT_ALL_LOCATIONS";
export const SELECT_SENSOR = "SELECT_SENSOR";
export const DESELECT_SENSOR = "DESELECT_SENSOR";
export const SELECT_SENSORS = "SELECT_SENSORS";
export const DESELECT_SENSORS = "DESELECT_SENSORS";
export const SELECT_ATOMIC_SENSOR = "SELECT_ATOMIC_SENSOR";
export const DESELECT_ATOMIC_SENSOR = "DESELECT_ATOMIC_SENSOR";
export const SELECT_ALL_ATOMIC_SENSORS = "SELECT_ALL_ATOMIC_SENSORS";
export const DESELECT_ALL_ATOMIC_SENSORS = "DESELECT_ALL_ATOMIC_SENSORS";
export const SELECT_UNREGISTERED_SENSORS = "SELECT_UNREGISTERED_SENSORS";
export const DESELECT_UNREGISTERED_SENSORS = "DESELECT_UNREGISTERED_SENSORS";
export const SELECT_GATEWAY = "SELECT_GATEWAY";
export const DESELECT_GATEWAY = "DESELECT_GATEWAY";
export const SELECT_GATEWAYS = "SELECT_GATEWAYS";
export const DESELECT_GATEWAYS = "DESELECT_GATEWAYS";
export const SELECT_LOCATION_TAG = "SELECT_LOCATION_TAG";
export const DESELECT_LOCATION_TAG = "DESELECT_LOCATION_TAG";
export const SELECT_SENSOR_TAG = "SELECT_SENSOR_TAG";
export const DESELECT_SENSOR_TAG = "DESELECT_SENSOR_TAG";
export const SELECT_DISPLAY = "SELECT_DISPLAY";
export const DESELECT_DISPLAY = "DESELECT_DISPLAY";
export const SELECT_SCREEN_GROUP = "SELECT_SCREEN_GROUP";
export const DESELECT_SCREEN_GROUP = "DESELECT_SCREEN_GROUP";
export const SELECT_SCREEN_VIEW = "SELECT_SCREEN_VIEW";
export const DESELECT_SCREEN_VIEW = "DESELECT_SCREEN_VIEW";
export const SELECT_REPORT = "SELECT_REPORT";
export const DESELECT_REPORT = "DESELECT_REPORT";
export const SELECT_CONTENT = "SELECT_CONTENT";
export const DESELECT_CONTENT = "DESELECT_CONTENT";
export const SELECT_USER_GROUP = "SELECT_USER_GROUP";
export const DESELECT_USER_GROUP = "DESELECT_USER_GROUP";
export const SELECT_USER = "SELECT_USER";
export const DESELECT_USER = "DESELECT_USER";
export const SELECT_INVITE = "SELECT_INVITE";
export const DESELECT_INVITE = "DESELECT_INVITE";
export const SELECT_CUSTOM_TAG = "SELECT_CUSTOM_TAG";
export const DESELECT_CUSTOM_TAG = "DESELECT_CUSTOM_TAG";
export const CLEAR_SELECTION = "CLEAR_SELECTION";
export const CHANGE_CONFIG_MODE = "CHANGE_CONFIG_MODE";
export const CHANGE_EDIT_MODE = "CHANGE_EDIT_MODE";
export const DESELECT_ALL = "DESELECT_ALL";

// Map selections
export const SELECT_MAP_FEATURE = "SELECT_MAP_FEATURE";
export const DESELECT_MAP_FEATURE = "DESELECT_MAP_FEATURE";
export const SELECT_LIST_FEATURE = "SELECT_LIST_FEATURE";
export const DESELECT_LIST_FEATURE = "DESELECT_LIST_FEATURE";
export const SET_SELECTED_FEATURES = "SET_SELECTED_FEATURES";
export const ADD_CREATED_FEATURE = "ADD_CREATED_FEATURE";
export const STORE_EDITED_FEATURES = "STORE_EDITED_FEATURES";
export const UPDATE_FEATURES = "UPDATE_FEATURES";
export const DELETE_FEATURES = "DELETE_FEATURES";
export const SET_MAP_MODE = "SET_MAP_MODE";
export const SET_HOVERED_FEATURE = "SET_HOVERED_FEATURE";

// Insight (DASHBOARDS)
export const GET_INSIGHT_DASHBOARDS = "GET_INSIGHT_DASHBOARDS";

// Map URL
export const GET_ROOM_MAP_LINK = "GET_ROOM_MAP_LINK";
export const CLEAR_ROOM_MAP_LINK = "CLEAR_ROOM_MAP_LINK";

// Display
export const GET_DISPLAY_CONFIG = "GET_DISPLAY_CONFIG";
export const CREATE_DISPLAY = "CREATE_DISPLAY";
export const GET_DISPLAYS = "GET_DISPLAYS";
export const GET_DISPLAY = "GET_DISPLAY";
export const UPDATE_DISPLAY = "UPDATE_DISPLAY";
export const DELETE_DISPLAY = "DELETE_DISPLAY";
export const RESTART_MESSAGE = "RESTART_MESSAGE";
export const UPDATE_DISPLAY_FORM = "UPDATE_DISPLAY_FORM";
export const RESET_DISPLAY_FORM = "RESET_DISPLAY_FORM";

// Screen groups
export const GET_SCREEN_GROUP = "GET_SCREEN_GROUP";
export const GET_SCREEN_GROUP_DETAILS = "GET_SCREEN_GROUP_DETAILS";
export const GET_SCREEN_GROUPS = "GET_SCREEN_GROUPS";
export const CREATE_SCREEN_GROUP = "CREATE_SCREEN_GROUP";
export const DELETE_SCREEN_GROUP = "DELETE_SCREEN_GROUP";
export const UPDATE_SCREEN_GROUP = "UPDATE_SCREEN_GROUP";
export const PATCH_SCREEN_GROUP = "PATCH_SCREEN_GROUP";
export const UPDATE_SCREEN_GROUP_FORM = "UPDATE_SCREEN_GROUP_FORM";
export const RESET_SCREEN_GROUP_FORM = "RESET_SCREEN_GROUP_FORM";
export const GET_SCREEN_DEVICE = "GET_SCREEN_DEVICE";
export const GET_SCREEN_DEVICES = "GET_SCREEN_DEVICES";
export const UPDATE_SCREEN_DEVICE = "UPDATE_SCREEN_DEVICE";
export const DELETE_SCREEN_DEVICE = "DELETE_SCREEN_DEVICE";
export const GET_SCREEN_LINK = "GET_SCREEN_LINK";
export const GET_SCREEN_LINKS = "GET_SCREEN_LINKS";
export const CREATE_SCREEN_LINK = "CREATE_SCREEN_LINK";
export const UPDATE_SCREEN_LINK = "UPDATE_SCREEN_LINK";
export const DELETE_SCREEN_LINK = "DELETE_SCREEN_LINK";
export const GET_WAITING_SCREEN_DEVICES = "GET_WAITING_SCREEN_DEVICES";
export const CLEAR_SCREEN_GROUP_DATA = "CLEAR_SCREEN_GROUP_DATA";
export const FORCE_SCREEN_RESTART = "FORCE_SCREEN_RESTART";
export const FORCE_ALL_SCREENS_RESTART = "FORCE_ALL_SCREENS_RESTART";

// Screen views
export const GET_SCREEN_VIEW = "GET_SCREEN_VIEW";
export const GET_SCREEN_VIEWS = "GET_SCREEN_VIEWS";
export const CREATE_SCREEN_VIEW = "CREATE_SCREEN_VIEW";
export const DELETE_SCREEN_VIEW = "DELETE_SCREEN_VIEW";
export const UPDATE_SCREEN_VIEW = "UPDATE_SCREEN_VIEW";
export const PATCH_SCREEN_VIEW = "PATCH_SCREEN_VIEW";
export const UPDATE_SCREEN_VIEW_FORM = "UPDATE_SCREEN_VIEW_FORM";
export const RESET_SCREEN_VIEW_FORM = "RESET_SCREEN_VIEW_FORM";
export const GET_SCREEN_LOCATION = "GET_SCREEN_LOCATION";
export const GET_SCREEN_FLOOR_MAP = "GET_SCREEN_FLOOR_MAP";
export const CLEAR_SCREEN_VIEW_DATA = "CLEAR_SCREEN_VIEW_DATA";

// Feedback
export const GOT_FEEDBACK_TYPES = "GOT_FEEDBACK_TYPES";
export const UPDATE_FEEDBACK_FORM = "UPDATE_FEEDBACK_FORM";

// Locationtags
export const GET_LOCATION_TAG = "GET_LOCATION_TAG";
export const GET_LOCATION_TAGS = "GET_LOCATION_TAGS";
export const CREATE_LOCATION_TAG = "CREATE_LOCATION_TAG";
export const UPDATE_LOCATION_TAG = "UPDATE_LOCATION_TAG";
export const DELETE_LOCATION_TAG = "DELETE_LOCATION_TAG";

// Sensortags
export const GET_SENSOR_TAG = "GET_SENSOR_TAG";
export const GET_SENSOR_TAGS = "GET_SENSOR_TAGS";
export const CREATE_SENSOR_TAG = "CREATE_SENSOR_TAG";
export const UPDATE_SENSOR_TAG = "UPDATE_SENSOR_TAG";
export const DELETE_SENSOR_TAG = "DELETE_SENSOR_TAG";

// Resources
export const GET_RESOURCE_CSV = "GET_RESOURCE_CSV";

// Jobs
export const GET_JOB = "GET_JOB";
export const GET_JOB_GROUP = "GET_JOB_GROUP";
export const GET_JOB_GROUPS = "GET_JOB_GROUPS";
export const DELETE_JOB = "DELETE_JOB";
export const CLEAR_CREATED_JOB = "CLEAR_CREATED_JOB";

// Users
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const DRAFT_USER_CHANGE = "DRAFT_USER_CHANGE";
export const CLEAR_USER = "CLEAR_USER";

// Invites
export const INVITE_USERS = "INVITE_USERS";
export const GET_INVITES = "GET_INVITES";
export const GET_INVITE = "GET_INVITE";
export const DELETE_INVITE = "DELETE_INVITE";
export const CLEAR_INVITE = "CLEAR_INVITE";

// Admins
export const GET_ADMINS = "GET_ADMINS";

// Support users
export const GET_SUPPORT_USERS = "GET_SUPPORT_USERS";
export const GRANT_ROLE = "GRANT_ROLE";
export const DRAFT_USER_ROLE = "DRAFT_USER_ROLE";

// User groups
export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const CREATE_USER_GROUP = "CREATE_USER_GROUP";
export const GET_USER_GROUP = "GET_USER_GROUP";
export const UPDATE_USER_GROUP = "UPDATE_USER_GROUP";
export const DELETE_USER_GROUP = "DELETE_USER_GROUP";
export const GET_USER_GROUP_USERS = "GET_USER_GROUP_USERS";
export const ADD_USER_GROUP_USERS = "ADD_USER_GROUP_USERS";
export const DELETE_USER_GROUP_USERS = "DELETE_USER_GROUP_USERS";
export const ADD_USER_USER_GROUPS = "ADD_USER_USER_GROUPS";
export const DELETE_USER_USER_GROUPS = "DELETE_USER_USER_GROUPS";
export const DRAFT_USER_GROUP_CHANGE = "DRAFT_USER_GROUP_CHANGE";
export const CLEAR_USER_GROUP = "CLEAR_USER_GROUP";

// Content
export const GET_ALL_CONTENT = "GET_ALL_CONTENT";
export const CREATE_CONTENT = "CREATE_CONTENT";
export const GET_CONTENT = "GET_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const DELETE_CONTENT = "DELETE_CONTENT";
export const DRAFT_CONTENT_CHANGE = "DRAFT_CONTENT_CHANGE";
export const CLEAR_CONTENT = "CLEAR_CONTENT";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const UPLOAD_CONTENT_IMAGE = "UPLOAD_CONTENT_IMAGE";

// Webex
export const GET_WEBEX_DEVICES = "GET_WEBEX_DEVICES";
export const GET_WEBEX_MACRO_STATUS = "GET_WEBEX_MACRO_STATUS";

// Simplified dashboard
export const GET_SENSOR_HISTORY = "GET_SENSOR_HISTORY";
export const INITIATE_SENSOR_HISTORY = "INITIATE_SENSOR_HISTORY";
export const STORE_DASHBOARD_QUERY_HASH = "STORE_DASHBOARD_QUERY_HASH";
export const STOP_GET_SENSOR_HISTORY = "STOP_GET_SENSOR_HISTORY";

// Custom tags
export const GET_CUSTOM_TAGS = "GET_CUSTOM_TAGS";
export const GET_CUSTOM_TAG = "GET_CUSTOM_TAG";
export const CREATE_CUSTOM_TAG = "CREATE_CUSTOM_TAG";
export const UPDATE_CUSTOM_TAG = "UPDATE_CUSTOM_TAG";
export const DELETE_CUSTOM_TAG = "DELETE_CUSTOM_TAG";
export const UPDATE_CUSTOM_TAG_DRAFT = "UPDATE_CUSTOM_TAG_DRAFT";
export const CLEAR_CUSTOM_TAG_DRAFT = "CLEAR_CUSTOM_TAG_DRAFT";
export const CLEAR_CUSTOM_TAG = "CLEAR_CUSTOM_TAG";

// Query filters
export const GET_FILTERS = "GET_FILTERS";
export const GET_FILTER = "GET_FILTER";
export const LOAD_FILTER = "LOAD_FILTER";
export const CREATE_FILTER = "CREATE_FILTER";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const DELETE_FILTERS = "DELETE_FILTERS";
export const SELECT_FILTER = "SELECT_FILTER";
export const DESELECT_FILTER = "DESELECT_FILTER";
export const CLEAR_ALL_SELECTED_FILTERS = "CLEAR_ALL_SELECTED_FILTERS";
export const UPDATE_FILTER_DRAFT = "UPDATE_FILTER_DRAFT";
export const HIGHLIGHT_FILTER = "HIGHLIGHT_FILTER";
export const CLEAR_HIGHLIGHTED_FILTER = "CLEAR_HIGHLIGHTED_FILTER";

// Front page
export const GET_SYSTEM_UPTIME = "GET_SYSTEM_UPTIME";
export const GET_OCCUPANCY_METRICS = "GET_OCCUPANCY_METRICS";
export const GET_UNIQUE_USER_LOGINS = "GET_UNIQUE_USER_LOGINS";
export const GET_FULLY_UTILIZED_MINUTES_WIDGET = "GET_FULLY_UTILIZED_MINUTES_WIDGET";
export const GET_NOT_UTILIZED_MINUTES_WIDGET = "GET_NOT_UTILIZED_MINUTES_WIDGET";
export const GET_NEVER_UTILIZED_WIDGET = "GET_NEVER_UTILIZED_WIDGET";
export const GET_AT_CAPACITY_MINUTES_WIDGET = "GET_AT_CAPACITY_MINUTES_WIDGET";
export const GET_UTILIZATION_INTRADAY_WIDGET = "GET_UTILIZATION_INTRADAY_WIDGET";
export const GET_UTILIZATION_DAY_WIDGET = "GET_UTILIZATION_DAY_WIDGET";
export const GET_UTILIZATION_WEEKDAY_WIDGET = "GET_UTILIZATION_WEEKDAY_WIDGET";
export const GET_UTILIZATION_MONTH_WIDGET = "GET_UTILIZATION_MONTH_WIDGET";
// export const GET_UTILIZATION_DISTRIBUTION_INTRADAY_WIDGET = "GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET";
// export const GET_UTILIZATION_DISTRIBUTION_DAY_WIDGET = "GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET";
// export const GET_UTILIZATION_DISTRIBUTION_WEEKDAY_WIDGET = "GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET";
export const GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET = "GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET";
export const GET_UTILIZATION_TOP_LOCATIONS_WIDGET = "GET_UTILIZATION_TOP_LOCATIONS_WIDGET";
export const GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET = "GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET";
export const GET_PEOPLE_COUNT_INTRADAY_WIDGET = "GET_PEOPLE_COUNT_INTRADAY_WIDGET";
export const GET_PEOPLE_COUNT_DAY_WIDGET = "GET_PEOPLE_COUNT_DAY_WIDGET";
export const GET_PEOPLE_COUNT_WEEKDAY_WIDGET = "GET_PEOPLE_COUNT_WEEKDAY_WIDGET";
export const GET_PEOPLE_COUNT_MONTH_WIDGET = "GET_PEOPLE_COUNT_MONTH_WIDGET";
export const GET_WIDGET_PROFILE = "GET_WIDGET_PROFILE";
export const SET_WIDGET_SEARCH = "SET_WIDGET_SEARCH";

// Reports
export const CREATE_WORKPLACE_QUERY = "CREATE_WORKPLACE_QUERY";
export const UPDATE_WORKPLACE_QUERY = "UPDATE_WORKPLACE_QUERY";
export const SET_WORKPLACE_QUERY = "SET_WORKPLACE_QUERY";
export const DELETE_WORKPLACE_QUERY = "DELETE_WORKPLACE_QUERY";
export const GET_WORKPLACE_CATEGORIES = "GET_WORKPLACE_CATEGORIES";
export const GET_WORKPLACE_FILTERS = "GET_WORKPLACE_FILTERS";
export const GET_UTILIZATION_INTRADAY = "GET_UTILIZATION_INTRADAY";
export const GET_UTILIZATION_DAYS = "GET_UTILIZATION_DAYS";
export const GET_UTILIZATION_WEEKDAYS = "GET_UTILIZATION_WEEKDAYS";
export const GET_UTILIZATION_WEEKS = "GET_UTILIZATION_WEEKS";
export const GET_UTILIZATION_MONTHS = "GET_UTILIZATION_MONTHS";
export const GET_PEOPLE_COUNT_INTRADAY = "GET_PEOPLE_COUNT_INTRADAY";
export const GET_PEOPLE_COUNT_DAYS = "GET_PEOPLE_COUNT_DAYS";
export const GET_PEOPLE_COUNT_WEEKDAYS = "GET_PEOPLE_COUNT_WEEKDAYS";
export const GET_PEOPLE_COUNT_WEEKS = "GET_PEOPLE_COUNT_WEEKS";
export const GET_PEOPLE_COUNT_MONTHS = "GET_PEOPLE_COUNT_MONTHS";
export const GET_REAL_COVERAGE_DEGREE = "GET_REAL_COVERAGE_DEGREE";

// Graph Resources
export const GET_GRAPH_RESOURCES = "GET_GRAPH_RESOURCES";
export const GET_GRAPH_RESOURCE = "GET_GRAPH_RESOURCE";
export const CREATE_GRAPH_RESOURCE = "CREATE_GRAPH_RESOURCE";
export const UPDATE_GRAPH_RESOURCE = "UPDATE_GRAPH_RESOURCE";
export const DELETE_GRAPH_RESOURCE = "DELETE_GRAPH_RESOURCE";

// Anomalies
export const GET_ANOMALIES = "GET_ANOMALIES";
export const GET_ANOMALY = "GET_ANOMALY";
export const UPDATE_ANOMALY = "UPDATE_ANOMALY";
export const CLEAR_ANOMALY = "CLEAR_ANOMALY";
export const DRAFT_ANOMALY_CHANGE = "DRAFT_ANOMALY_CHANGE";