import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";
import { sortSensorTypes, sensorTypeIgnoreList } from "../dashboardHelpers";
import { flattenHierarchy } from "../locationHelpers";

const initialState = {
  currentQuery: {},
  currentQueryHash: null,
  currentQueryParams: null,
  completedQueries: {},
  loadingQueries: {},
  hierarchy: [],
  flatHierarchy: {}
};

export default function locationQueryReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.NEW_QUERY_LOCATIONS) {
        const queryHash = action.metadata.queryHash;
        return {...state, currentQuery: action.metadata.query, currentQueryHash: queryHash, loadingQueries: { ...state.loadingQueries, [queryHash]: "loading" } };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.NEW_QUERY_LOCATIONS) {
        const queryHash = action.metadata.queryHash;
        if (JSON.stringify(state.currentQueryHash) !== JSON.stringify(action.metadata.queryHash)) {
          return state;
        }

        // Create a complete list of sensor types for all locations in search results
        let sensorTypes = [];
        action.payload.results.some(location => {
          sensorTypes = [...new Set([...sensorTypes, ...state.flatHierarchy[location._id].recordedProperties ?? []])]; 
        });

        // Replace footfallIn/Out with peopleCount
        if (sensorTypes.includes("footfallCountIn") && sensorTypes.includes("footfallCountOut")) {
          sensorTypes = sensorTypes.filter(type => !["footfallCountIn", "footfallCountOut", "egress", "ingress", "peopleCount"].includes(type));
          sensorTypes = [...sensorTypes, "peopleCount"];
        }

        // Replace motionCount with occupiedMinutes
        if (sensorTypes.includes("motionCount")) {
          sensorTypes = sensorTypes.filter(type => !["occupiedMinutes", "motionCount"].includes(type));
          sensorTypes = [...sensorTypes, "occupiedMinutes"];
        }

        // Ignore spesific sensor types
        sensorTypes = sensorTypes.filter(sensorType => !sensorTypeIgnoreList.includes(sensorType));

        // Sort sensors by type
        sensorTypes = sortSensorTypes(sensorTypes);
        
        return {...state, completedQueries: { ...state.completedQueries, [queryHash]: { locations: action.payload.results, sensorTypes } }, loadingQueries: { ...state.loadingQueries, [queryHash]: "completed" } };
      }

      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {
        let newState = {...state};

        // Flatten hierarchy
        let flatHierarchy = {};
        if (action.payload.length > 0) {
          flattenHierarchy(action.payload, [], flatHierarchy);
        }

        newState.hierarchy = action.payload;
        newState.flatHierarchy = flatHierarchy;
        
        return newState;
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.NEW_QUERY_LOCATIONS) {
        const queryHash = action.metadata.queryHash;
        if (JSON.stringify(state.currentQueryHash) === JSON.stringify(queryHash)) {
          toast.error(`${statusCode}: Could not find any locations`); 
          return {...state, completedQueries: { ...state.completedQueries, [queryHash]: null }, loadingQueries: { ...state.loadingQueries, [queryHash]: "error" } };
        }
      }
      
      return state;
    }

    case types.SET_LOCATION_SEARCH: {
      return {...state, currentQueryParams: action.payload };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
