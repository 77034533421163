import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface AnimatedBarProps {
  value: number;
  isVertical?: boolean;
  color: string;
}

const AnimatedBar: React.FC<AnimatedBarProps> = ({ value, isVertical = true, color }) => {
  const [size, setSize] = useState<number>(0); // Start with 0

  useEffect(() => {
    // Animate to the final size after the component mounts
    setSize(value);
  }, [value]); // Depend on finalSize to re-run this effect if finalSize changes


  if (isVertical) {
    return <VerticalBar $size={size} $color={color} />;
  }

  return <HorizontalBar $size={size} $color={color} />;
};

export default AnimatedBar;

const VerticalBar = styled.div<{ $size: number, $color: string }>`
  width: 100%;
  height: ${props => props.$size}%;
  background-color: ${props => props.$color};
  transition: height 1s;
  border-radius: 8px;
`;

const HorizontalBar = styled.div<{ $size: number, $color: string }>`
  width: ${props => props.$size}%;
  height: 100%;
  background-color: ${props => props.$color};
  transition: width 1s;
  border-radius: 8px;
`;