import React from "react";
import Tag from "../Tag";
import isEmpty from "lodash/isEmpty";
import { Visible, Hidden } from "react-grid-system";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import style from "./style.module.scss"

const FrontPageSearchBox = ({ query = { customTags: [], text: "", placeholder: "Search" }, onQueryClick = () => {}, onQueryChange = () => {}, onKeyDown = () => {}, onSearchClick = () => {}, onRemoveTagClick = () => {}, onClear = () => {} }) => (
  <>
  <div className={style.searchContainer}>
    <div className={style.box}>
      <div className={style.queryContainer} data-first={true} data-last={true}>
        <div className={style.innerBox} onClick={() => onQueryClick()}>
          {query.customTags.map(tag => <div className={style.tag} key={tag.id}><Tag text={tag.name} color={tag.colorTheme} onRemoveClick={() => onRemoveTagClick(tag)} /></div>)}
          <input 
            // type="search"
            className={style.searchField} 
            name={`search`} 
            placeholder={query.customTags.length === 0 ? query.placeholder : ""} 
            onChange={(event) => onQueryChange(event.target.value)}
            onKeyDown={(event) => onKeyDown(event)}
            value={query.text}
            autoComplete="off"
            />
          { // button to clear the search field  style={{ marginRight: "12px" }}
            (!isEmpty(query.text) || !isEmpty(query.customTags)) && (
              <div className={style.clearButton} onClick={() => onClear()}>
                <FontAwesomeIcon icon={faXmark} color={"#222"} />
              </div>
            )
          }
        </div>
      </div>
    </div>
    <Hidden xs sm>
      <div className={style.searchButton} onClick={() => onSearchClick()}>
        <div className={style.searchButtonText}>Search</div>
      </div>
    </Hidden>
  </div>

  <Visible xs sm>
    <div className={style.searchButton} onClick={() => onSearchClick()} style={{ width: "100%", marginLeft: 0, marginTop: "10px" }}>
      <div className={style.searchButtonText}>Search</div>
    </div>
  </Visible>
  </>
);

export default FrontPageSearchBox;