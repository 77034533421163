import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import IntersectionObserverWrapper from "../IntersectionObserverWrapper";
import style from "./style.module.scss";

class SubNavigation extends Component {

  constructor(props) {
		super(props);
    this.state = { hidden: true };
    this.menuToggle = this.menuToggle.bind(this);
	}

  menuToggle() {
    this.setState(prevState => ({ hidden: !prevState.hidden }));
  }


  render() {

    if (this.props.slim && this.props.normalMargin) {
      return (
        <div className={style.subHeader}>
          <div className={style.subHeaderMargins}>
            <IntersectionObserverWrapper
              key={JSON.stringify(this.props.links)}
              links={this.props.links}
              history={this.props.history}
              small={this.props.slim}
              selectedIndex={this.props.selectedIndex}
              onSelectIndex={this.props.onClick}
            />
          </div>
        </div>
      )
    }

    return (
      <div className={this.props.slim ? style.tabsNoMargin : style.tabs}>
        <IntersectionObserverWrapper
          key={JSON.stringify(this.props.links)}
          links={this.props.links}
          history={this.props.history}
          small={this.props.slim}
          selectedIndex={this.props.selectedIndex}
          onSelectIndex={this.props.onClick}
        />
      </div>
    );
  }
}

export default withRouter(SubNavigation);