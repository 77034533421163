import React from "react";
import Freetext from "../../components/Freetext";
import style from "./style.module.scss"

const PrivacyPolicy = () => (
  <div className={style.outerScroll}>
    <div className={style.container}>
      <div className={style.innerContainer}>

        <Freetext header="Privacy policy" />

        <p>This website use cookies to remember your login state. By continuing to use this website, you agree to our use of cookies.</p>

        <h2>Cookies</h2>
        <p>We rely on cookies to log you in. These cookies are used to securely communicate your authentication state between our services.</p>
        
        <p>The authorization procedure may rely on cookies from the following third-party services:</p>
        <ul>
          <li>Entra ID</li>
          <li>Google Account</li>
          <li>LinkedIn</li>
        </ul>

        <p>The interactive map feature may rely on cookies from the following third-party services:</p>
        <ul>
          <li>MazeMap</li>
        </ul>

        <p>For more information on how these third-party services use cookies, please see their respective privacy policies.</p>

        <p>Additionally this website use cookies to ensure proper functionality. These essential cookies do not track personal data. We do not use cookies for analytics, marketing, or any other non-essential purposes. Your privacy and the functionality of our service are our top priorities.</p>

        <h2>Data processing</h2>
        <p>Data processing is governed by a Data Processing Agreement established between BLDNG.ai AS and the account holder.</p>

        <h2>Updates to this privacy policy</h2>
        <p>We may update this privacy policy to reflect changes in our data practices. We will notify you of any significant changes via email.</p>

        <h2>Contact us</h2>
        <p>If you have any questions or concerns about your privacy, please contact us at <a href="mailto:support@bldng.ai">support@bldng.ai</a>.</p> 

        <div className={style.footnote}>Last updated 13/12/2023</div>
        
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;
