import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { isNumber } from "lodash";

interface KPIBoxProps {
  targetValue: number; // Target percentage value
  targetChange?: number; // Target change value
  thresholdValue: number; // Max value
  zeroColor: string; // Zero color
  minColor: string; // Lowest color
  maxColor: string; // Highest color
  duration: number; // Animation duration in milliseconds
}

const KPIBox2: React.FC<KPIBoxProps> = ({ targetValue, targetChange, thresholdValue, zeroColor, minColor, maxColor, duration }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [currentChange, setCurrentChange] = useState<number | undefined>(undefined);
  const [currentColor, setCurrentColor] = useState("#ffffff");

  useEffect(() => {
    const startTime = Date.now();

    const hexToRgb = (hex: string) => {
      const bigint = parseInt(hex.slice(1), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    };

    const rgbToHex = (r: number, g: number, b: number) => {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    };

    const interpolateColor = (color1: string, color2: string, factor: number) => {
      const [r1, g1, b1] = hexToRgb(color1);
      const [r2, g2, b2] = hexToRgb(color2);
      const r = Math.round(r1 + factor * (r2 - r1));
      const g = Math.round(g1 + factor * (g2 - g1));
      const b = Math.round(b1 + factor * (b2 - b1));
      return rgbToHex(r, g, b);
    };

    const animate = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Ensure progress does not exceed 1

      // Use an easing function to match the CSS transition flow, if necessary
      const easeInOut = (t: number) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      const easedProgress = easeInOut(progress);

      const newValue = easedProgress * (targetValue - currentValue) + currentValue;
      setCurrentValue(newValue);

      const thisCurrentChange = currentChange ?? 0;
      const newChange = isNumber(targetChange) ? (easedProgress * (targetChange - thisCurrentChange) + thisCurrentChange) : undefined;
      setCurrentChange(newChange);

      if (newValue === 0) {
        setCurrentColor(zeroColor);
      }
      else {
        const valueFactor = Math.max(Math.min(newValue / thresholdValue, 1), 0);
        const newColor = interpolateColor(minColor, maxColor, valueFactor);
        setCurrentColor(newColor);
      }

      if (progress < 1) {
        requestAnimationFrame(animate);
      } 
      else {
        // Ensure it ends precisely at the target value
        setCurrentValue(targetValue); 
        setCurrentChange(targetChange);
      }
    };

    requestAnimationFrame(animate);
  }, [targetValue, targetChange, thresholdValue, minColor, maxColor, duration]);

  const result = isNaN(currentValue) ? "-" : Math.floor(currentValue);

  const valueElement = (
    <GraphValue>
      {result}
    </GraphValue>
  );

  let changeElement;
  if (isNumber(currentChange)) {
    const changeIcon = <FontAwesomeIcon icon={faArrowUp} color="#ffffff" fontSize={14} transform={{ rotate: currentChange > 0 ? 45 : (currentChange === 0 ? 90 : 135) }} style={{ marginRight: "3px" }} />;
    const percentageChangeString = `${Math.floor(Math.abs(currentChange))}`;

    changeElement = (
      <GraphValueChange>
        {changeIcon}
        {percentageChangeString}
      </GraphValueChange>
    );
  }

  return (
    <Box $color={currentColor}>
      <InnerBox>
        { valueElement }
        { changeElement }
      </InnerBox>
    </Box>
  );
};

export default KPIBox2;

const Box = styled.div<{ $color: string }>`
  width: 100%;
  height: 100%;
  color: white;
  background-color: ${props => props.$color};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 5px;
  margin-right: 5px;
`;

const InnerBox = styled.div`
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // height: 30px;
`;

const LeftSide = styled.div`
  font-size: 32px;
  margin-right: 3px;
  height: 100%;
`;

const RightSide = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: end;
  height: 100%;
`;

const GraphValue = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 32px;
  line-height: 28px;
  // font-weight: 600;
  color: #fff;
`;

const GraphValueChange = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-left: 3px;
`;