import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import PercentageText from "./percentageText";
import AnimatedBar from "./animatedBar";
import { WidgetValueType } from "./types";
import styled from "styled-components";

interface VerticalBarGraphProps {
  data: any[];
  valueType: WidgetValueType;
  max?: number;
  description: string;
  showChangeBar?: boolean;
  showMaxLine?: boolean;
}

const VerticalBarGraph: React.FC<VerticalBarGraphProps> = ({ data, valueType, max, description, showChangeBar, showMaxLine }) => {
  // console.log("VerticalBarGraph", valueType, max, showChangeBar, showMaxLine, data);

  const maxValue = Math.max(...data.map(item => item.value));

  const columns = data.map((item, index) => {
    let valueInPercentage = null;
    let value = null;
    switch (valueType) {
      case WidgetValueType.Percentage:
        valueInPercentage = Math.round(item.value * 100 * 100) / 100;
        value = valueInPercentage;
        break;
      case WidgetValueType.Count:
        if (max) {
          valueInPercentage = Math.round((item.value / max) * 100 * 100) / 100;
        }
        else {
          valueInPercentage = maxValue > 0 ? (Math.round((item.value / maxValue) * 100 * 100) / 100) : 0;
        }
        value = item.value;
        break;
      case WidgetValueType.Minutes:
        valueInPercentage = maxValue > 0 ? (Math.round((item.value / maxValue) * 100 * 100) / 100) : 0;
        value = item.value;
        break;
      default:
        break;
    }
    
    if (valueInPercentage === null || valueInPercentage < 0) {
      return null;
    }

    return (
      <Column key={index}>
        <ValueBox>
          <PercentageText valueType={valueType} targetValue={value} duration={1000} />
        </ValueBox>
        <BarContainer>
          { showMaxLine && max && <MaxLine $value={80} $showWhiteLine={valueInPercentage > 100} /> }
          <AnimatedBar isVertical value={(showMaxLine && max) ? valueInPercentage*80/100 : valueInPercentage} color={item.color} />
        </BarContainer>
        <Description>{item.description}</Description>
      </Column>
    );
  });


  // console.log("VerticalBarGraph", valueType, max, showChangeBar, showmaxCount, data, maxValue);

  // Show the change bar if there are only two data points
  if (showChangeBar && data.length === 2) {

    const change = data[1].value - data[0].value;
    let maxValuePosition = null;
    let changeInPercentage = null;

    let value = null;
    switch (valueType) {
      case WidgetValueType.Percentage:
        changeInPercentage = Math.abs(Math.round(change * 100 * 100) / 100);
        value = Math.abs(changeInPercentage);
        maxValuePosition = 100 - Math.round(maxValue * 100 * 100) / 100;
        break;
      case WidgetValueType.Count:
        if (max) {
          const maxPercentage = Math.round((maxValue / max) * 100 * 100) / 100;
          maxValuePosition = 100 - maxPercentage;
          changeInPercentage = Math.abs(change / max); // ???????
        }
        else {
          changeInPercentage = Math.abs(change / maxValue) * 100;
          maxValuePosition = 0;
        }
        value = Math.abs(change);
        break;
      case WidgetValueType.Minutes:
        changeInPercentage = Math.abs(change / maxValue) * 100; // ???????
        value = Math.abs(change);
        maxValuePosition = 0;
        break;
      default:
        break;
    }

    if (value === null || changeInPercentage === null || maxValuePosition === null) {
      return null;
    }

    if (!isFinite(changeInPercentage)) {
      changeInPercentage = 0;
    }

    let changeIcon;
    if (change > 0) {
      changeIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
    } 
    else if (change < 0) {
      changeIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
    }
    else if (change === 0) {
      changeIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
    }

    // Limit the change bar to 100%
    changeInPercentage = Math.min(100, changeInPercentage);
    maxValuePosition = Math.max(0, Math.min(100, maxValuePosition));

    // Add the change bar before the last column
    columns.splice(-1, 0, (
      <Column key="change">
        <ChangeValue>
          {changeIcon}
          <PercentageText valueType={valueType} targetValue={value} duration={1000} />
        </ChangeValue>
        
        <ChangeBarContainer>
          <ChangeBarPosition $size={100} $top={maxValuePosition}>
            <AnimatedBar value={changeInPercentage} color={data[0].color} />
          </ChangeBarPosition>
        </ChangeBarContainer>
      </Column>
    ));
  }

  return (
    <Container>
      {columns}
      <GraphDescription>{description}</GraphDescription>
    </Container>
  );
};

export default VerticalBarGraph;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 107px;
  // width: 60px;
  margin-right: 5px;
  // margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 0;
  // min-width: 40px;
  max-width: 60px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 55px;
  background-color: #eee;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 5px;
`;

// Dotted line at the top of the bar
const MaxLine = styled.div<{ $value: number, $showWhiteLine: boolean }>`
  position: absolute;
  width: 100%;
  height: 0px;
  bottom: ${props => props.$value}%;
  z-index: 1;
  pointer-events: none;
  border-top: ${props => props.$showWhiteLine ? "1px dashed #fff" : "1px dashed #999"};
`;

const ValueBox = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #666;
  background-color: #eee;
  border-radius: 8px;
  width: 100%;
  height: 22px;
  box-sizing: border-box;
  margin-bottom: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  font-size: 12px;
  color: #555;
  text-align: center;
  height: 16px;
  text-wrap: nowrap;
`;

const GraphDescription = styled.div`
  font-size: 12px;
  color: #555;
  margin-top: 27px;
  // width: 60px;
  white-space: pre-line;
`;

const ChangeBarContainer = styled.div<{ $bottom?: number }>`
  position: relative;
  display: flex;
  width: 100%;
  height: 55px;
  pointer-events: none;
`;

const ChangeBarPosition = styled.div<{ $size: number, $top: number }>`
  position: absolute;
  width: 100%;
  height: ${props => props.$size}%;
  top: ${props => props.$top}%; 
  transition: top 1s;
  pointer-events: none;
`;

const ChangeValue = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #666;
  border-radius: 8px;
  width: 100%;
  height: 22px;
  box-sizing: border-box;
  margin-bottom: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
`;