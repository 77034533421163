import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faEmptyStar } from "@fortawesome/free-regular-svg-icons";
import SearchBox from "../../../components/SearchBox";
import styled from "styled-components";
import { get } from "lodash";
import FrontPageSearchBox from "../../../components/FrontPageSearchBox";

class HomeHeader extends Component {

  constructor(props) {
    // console.log("HomeHeader.constructor()", props);
    super(props);
    this.state = {
      searchText: "",
      hoverStar: false
    };

    this.onHoverStar = this.onHoverStar.bind(this);
    this.onSearchChanged = this.onSearchChanged.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  static propTypes = {
    pages: PropTypes.array.isRequired,
    selectedPageId: PropTypes.string,
    selectedLocation: PropTypes.object,
    favoriteLocationId: PropTypes.string,
    onSelectedPageChanged: PropTypes.func.isRequired,
    onToggleFavorite: PropTypes.func.isRequired,
  };

  onHoverStar(hover) {
    this.setState({ hoverStar: hover });
  }

  onPageClick(page) {
    this.props.onPageClick(page);
  }

  onSearchChanged(value) {
    this.setState({ searchText: value });
  }

  onSearchClear() {
    this.setState({ searchText: "" });
  }

  onSearchClick() {
    this.props.onSearchClick(this.state.searchText);
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
      this.props.onSearchClick(this.state.searchText);
    }
  }

  render() {
    // console.log("HomeHeader.render()", this.props);

    // Get favorite location style
    const selectedLocationId = get(this.props.selectedLocation, "id", "");
    const selectedLocationName = get(this.props.selectedLocation, "name", "");
    const star = this.props.favoriteLocationId === selectedLocationId ? faStar : faEmptyStar;
    const starColor = this.state.hoverStar ? "#aaa" : this.props.favoriteLocationId === selectedLocationId ? "#f5a623" : "#6b7073";

    const pageMenu = this.props.pages.map((page) => (
      <MenuButton
        key={page.id}
        onClick={() => this.props.onSelectedPageChanged(page.id)}
        $isSelected={this.props.selectedPageId === page.id}
        $selectedColor={page.color}
        $color={"#333"}>
        <span style={{ userSelect: "none" }}>{page.name}</span>
      </MenuButton>
    ));

    const searchBox = (
      <SearchContainer>
        <h1 style={{ display: "inline-block" }}>{selectedLocationName}</h1>
        <SearchBox
          placeholder="What are you looking for?"
          value={this.state.searchText}
          onSearchChanged={this.onSearchChanged}
          onSearchClick={this.onSearchClick}
        />
      </SearchContainer>
    );

    //{ id: "test", name: "møterom", colorTheme: "#94224F" }
    const searchBox2 = (
      <SearchContainer2>
        <FrontPageSearchBox
          // query={{ customTags, text: this.state.search, placeholder: "Search", searchType: this.state.searchType }}
          query={{ text: this.state.searchText, customTags: [{ id: "test", name: "møterom", colorTheme: "#94224F" }], placeholder: "What are you looking for?" }}
          onQueryChange={this.onSearchChanged}
          onSearchClick={this.onSearchClick}
          onKeyDown={this.onKeyDown}
          onClear={this.onSearchClear}
          // onRemoveTagClick={this.onRemoveTagClick}
        />
      </SearchContainer2>
    );

    return (
      <Container>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h1 style={{ display: "inline-block" }}>{selectedLocationName}</h1>
            <StarContainer onClick={(event) => { this.props.onToggleFavorite(selectedLocationId); event.stopPropagation(); }} onMouseEnter={() => this.onHoverStar(true)} onMouseLeave={() => this.onHoverStar(false)}>
              <FontAwesomeIcon icon={star} fontSize={22} color={starColor} />
            </StarContainer>
            <p>Activity in this building in {moment().subtract(1, "month").format("MMMM")}.</p>
          </div>
        </div>
        {/* <h1 style={{ display: "inline-block" }}>Home</h1> */}
        {/* { searchBox2 } */}
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", columnGap: "10px", flexWrap: "wrap" }}>
          { pageMenu }
        </div>
      </Container>
    );
  }
}

export default HomeHeader;

const Container = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 1240px;

  @media (max-width: 1450px) and (min-width: 1060px) {
    max-width: 820px;
  }

  @media (max-width: 1060px) {
    max-width: 400px;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
`;

const SearchContainer2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const MenuButton = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => props.$isSelected ? (props.$selectedColor ?? "#1c4d82") : "#fff"};
  border-color: ${(props) =>props.$isSelected ? (props.$selectedColor ?? "#1c4d82") : (props.$color ?? "#1c4d82")};
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding: 8px 15px;

  span {
    color: ${(props) => props.$isSelected ? "#fff" : (props.$color ?? "#1c4d82")};
    font-weight: 600;
    font-size: 15px;
  }
`;

const StarContainer = styled.div`
  display: inline-flex;
  padding: 0px 0px 0px 10px;
  align-items: start;
  justify-content: start;
  cursor: pointer;
`;