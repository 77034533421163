import { toast } from "react-toastify";
import moment from "moment";
import { isNil, get } from "lodash";
import { LOCATION_DATA_ERROR } from "../dashboardHelpers";
import * as types from "../ApiTypes";

const initialState = {
  id: null,
  data: {},
  dataLoadingStatus: {},
  queryHash: null
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SENSOR_HISTORY) {

        const dataKey = action.metadata.hashKey;

        return {
          ...state, 
          id: action.metadata.locationId,
          data: { 
            ...state.data,
            [dataKey]: []
          },
          dataLoadingStatus: { 
            ...state.dataLoadingStatus, 
            [dataKey]: { type: "loading", message: "Loading..." }
          }
        };
      }

      if (action.fetchType === types.INITIATE_SENSOR_HISTORY) {

        let data = { ...state.data };
        let dataLoadingStatus = { ...state.dataLoadingStatus };
        action.metadata.hashKeys.forEach(hashKey => {
          data[hashKey] = [];
          dataLoadingStatus[hashKey] = { type: "loading", message: "Loading..." };
        });

        return {
          ...state,
          id: action.metadata.locationId,
          data,
          dataLoadingStatus
        };
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SENSOR_HISTORY) { 
      
        var newData = [];
        const dataKey = action.metadata.hashKey;
        const capacity = action.metadata.capacity;
        const sensorType = dataKey.split("-")[0];
        switch (sensorType) {
          case "temperature":
            newData = action.payload.map(sample => ({
              avg: !isNil(sample.avg) ? (sample.avg-273.15) : undefined,
              max: !isNil(sample.max) ? (sample.max-273.15) : undefined,
              min: !isNil(sample.min) ? (sample.min-273.15) : undefined,
              unit: "°C",
              weekday: !isNil(sample.dayOfWeek) ? (sample.dayOfWeek) : undefined,
              datetime: sample.datetime
            }));
            break;
          case "humidity":
            newData = action.payload.map(sample => ({
              avg: !isNil(sample.avg) ? (sample.avg) : undefined,
              max: !isNil(sample.max) ? (sample.max) : undefined,
              min: !isNil(sample.min) ? (sample.min) : undefined,
              unit: "%",
              weekday: !isNil(sample.dayOfWeek) ? (sample.dayOfWeek) : undefined,
              datetime: sample.datetime
            }));
            break;
          case "carbonDioxideLevel":
            newData = action.payload.map(sample => ({
              avg: !isNil(sample.avg) ? (sample.avg) : undefined,
              max: !isNil(sample.max) ? (sample.max) : undefined,
              min: !isNil(sample.min) ? (sample.min) : undefined,
              unit: " ppm",
              weekday: !isNil(sample.dayOfWeek) ? (sample.dayOfWeek) : undefined,
              datetime: sample.datetime
            }));
            break;
          case "vocLevel":
          case "volatileOrganicCompounds":
            newData = action.payload.map(sample => ({
              avg: !isNil(sample.avg) ? (sample.avg) : undefined,
              max: !isNil(sample.max) ? (sample.max) : undefined,
              min: !isNil(sample.min) ? (sample.min) : undefined,
              unit: " ppm",
              weekday: !isNil(sample.dayOfWeek) ? (sample.dayOfWeek) : undefined,
              datetime: sample.datetime
            }));
            break;
          case "soundPressureLevel":
          case "ambientSoundPressureLevel":
            newData = action.payload.map(sample => ({
              avg: !isNil(sample.avg) ? Math.round(sample.avg) : undefined,
              max: !isNil(sample.max) ? Math.round(sample.max) : undefined,
              min: !isNil(sample.min) ? Math.round(sample.min) : undefined,
              unit: " dB",
              datetime: sample.datetime
            }));
            break;
          case "atmosphericPressure":
            newData = action.payload.map(sample => ({
              avg: (sample.avg/1000),
              max: !isNil(sample.max) ? (sample.max/1000) : undefined,
              min: !isNil(sample.min) ? (sample.min/1000) : undefined,
              unit: " hPa",
              datetime: sample.datetime
            }));
            break;
          case "peopleCount":
          case "peopleCountInBuildings":
          case "peopleCountInFloors":
          case "peopleCountInZones":
          case "peopleCountInRooms":
          case "peopleCountInMeetingRooms":
            newData = action.payload.map(sample => ({
              avg: !isNil(sample.avg) ? (sample.avg) : undefined,
              max: !isNil(sample.max) ? (sample.max) : undefined,
              min: !isNil(sample.min) ? (sample.min) : undefined,
              capacity: !isNil(capacity) ? (capacity) : undefined,
              count: !isNil(sample.count) ? (sample.count) : undefined,
              sum: !isNil(sample.sum) ? (sample.sum) : undefined,
              value: !isNil(sample.value) ? (sample.value) : undefined,
              unit: "",
              weekday: !isNil(sample.dayOfWeek) ? (sample.dayOfWeek) : undefined,
              datetime: sample.datetime
            }));
            break;
          case "occupiedMinutes":
            newData = action.payload.map(sample => ({
              avg: !isNil(sample.avg) ? (sample.avg) : undefined,
              max: !isNil(sample.max) ? (sample.max) : undefined,
              min: !isNil(sample.min) ? (sample.min) : undefined,
              total: !isNil(sample.total) ? (sample.total) : undefined,
              unit: "%",
              weekday: !isNil(sample.dayOfWeek) ? (sample.dayOfWeek) : undefined,
              datetime: sample.datetime
              // datetime: moment(sample.hourOfDay, "HH:mm:ss").toDate()
            }));
            break;
          case "occupiedCompareDayOfWeek":
            newData = action.payload.map(sample => ({
              max: !isNil(sample.max) ? Math.floor(sample.max) : undefined,
              unit: "%",
              weekday: !isNil(sample.dayOfWeek) ? (sample.dayOfWeek) : undefined,
              entityId: sample.entityId,
              name: sample.name
            }));
            break;
          case "maxOccupiedDayOfWeek":
            action.payload.forEach(sample => {
              const weekday = moment(sample.datetime).isoWeekday();
              let existingDataIndex = newData.findIndex(data => data.entityId === sample.entityId)
              if (existingDataIndex !== -1) {
                const existingAboveValue = get(newData[existingDataIndex], `above.[${weekday}]`, 0);
                const existingBelowValue = get(newData[existingDataIndex], `below.[${weekday}]`, 0);
                if (sample.max > 80) {
                  newData[existingDataIndex].above[weekday] = existingAboveValue + 1;
                  newData[existingDataIndex].above.all = newData[existingDataIndex].above.all + 1;
                }
                else {
                  newData[existingDataIndex].above[weekday] = existingAboveValue;
                }
                if (sample.max < 20) {
                  newData[existingDataIndex].below[weekday] = existingBelowValue + 1;
                  newData[existingDataIndex].below.all = newData[existingDataIndex].below.all + 1;
                }
                else {
                  newData[existingDataIndex].below[weekday] = existingBelowValue;
                }
              }
              else {
                newData.push({
                  entityId: sample.entityId,
                  name: sample.name,
                  above: {
                    [weekday]: sample.max > 80 ? 1 : 0,
                    all: sample.max > 80 ? 1 : 0
                  },
                  below: {
                    [weekday]: sample.max < 20 ? 1 : 0,
                    all: sample.max < 20 ? 1 : 0
                  }
                });
              }
            });
            break;

          case "activeConferenceCall":
          case "activeLocalPresentation":
            newData = action.payload.map(sample => ({
              avg: !isNil(sample.avg) ? Math.round(sample.avg*100) : undefined,
              unit: "%",
              datetime: sample.datetime
              // datetime: moment(sample.hourOfDay, "HH:mm:ss").toDate()
            }));
            break;
          default:
            newData = action.payload.map(sample => ({
              avg: !isNil(sample.avg) ? (sample.avg) : undefined,
              max: !isNil(sample.max) ? (sample.max) : undefined,
              min: !isNil(sample.min) ? (sample.min) : undefined,
              sum: !isNil(sample.sum) ? (sample.sum) : undefined,
              value: !isNil(sample.value) ? (sample.value) : undefined,
              count: !isNil(sample.count) ? (sample.count) : undefined,
              unit: "",
              weekday: !isNil(sample.dayOfWeek) ? (sample.dayOfWeek) : undefined,
              hourOfDay: !isNil(sample.hourOfDay) ? (sample.hourOfDay) : undefined,
              quarterOfDay: !isNil(sample.quarterOfDay) ? (sample.quarterOfDay) : undefined,
              datetime: sample.datetime,
              entityId: sample.entityId,
              name: sample.name
            }));
            break;
        }

        return {
          ...state,
          data: { 
            ...state.data, 
            [dataKey]: newData
          },
          dataLoadingStatus: { 
            ...state.dataLoadingStatus, 
            [dataKey]: { type: "finished" } 
          }
        };
      }

      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_SENSOR_HISTORY) {

        // const statusCode = get(action, "payload.response.status", "Error");
        const dataKey = action.metadata.hashKey;
        toast.error(`Could not get data`);

        return {
          ...state,
          data: { 
            ...state.data, 
            [dataKey]: []
          },
          dataLoadingStatus: {
            ...state.dataLoadingStatus,
            [dataKey]: { type: "error", message: `Could not get data` }
          }
        };
      }
      
      return state;
    }

    case types.STOP_GET_SENSOR_HISTORY: {
      const dataKey = action.payload.hashKey;

      let message = "";
      if (action.payload.error === LOCATION_DATA_ERROR.TOO_OLD) {
        message = "This query cannot be done on data older than 1 month. Please select a newer time period.";
      }
      else if (action.payload.error === LOCATION_DATA_ERROR.TOO_MANY_LOCATIONS) {
        message = `This query cannot include ${action.payload.count} locations. Please limit your query to ${action.payload.max} locations with the filter.`;
      }
      else if (action.payload.error === LOCATION_DATA_ERROR.NO_LOCATIONS) {
        message = "This data is not available for any location in the query.";
      }

      return {
        ...state,
        data: { 
          ...state.data, 
          [dataKey]: []
        },
        dataLoadingStatus: { 
          ...state.dataLoadingStatus, 
          [dataKey]: { type: "error", message }
        }
      };
    }

    case types.CLEAR_DATA, types.CLEAR_DATA: {
      // console.log("clear query hash");
      return initialState;
    }

    case types.STORE_DASHBOARD_QUERY_HASH: {
      // console.log("store query hash", action.payload);
      return {
        ...state,
        // data: {},
        // dataLoadingStatus: {},
        queryHash: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
