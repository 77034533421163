import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { get, isEmpty } from "lodash";
import Loader from "../../components/Loader";
import FrontPageSearchBox from "../../components/FrontPageSearchBox";
import Locations from "./ContentViews/locations";
import { getLocation } from "../../locationHelpers";
import * as locationActions from "../../actions/locations";
import * as widgetActions from "../../actions/widgets";
import styled from "styled-components";

class SearchLocations extends Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.history.location.search);

    this.state = {
      search: params.get("search") ?? "",
      displayType: params.get("type") ?? "all",
      queryHash: null,
      locationQueries: [],
      hasLoadedView: false,
    };

    this.storeQueryParams = this.storeQueryParams.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onRunQuery = this.onRunQuery.bind(this);
    this.onDisplayTypeChanged = this.onDisplayTypeChanged.bind(this);

    this.storeQueryParams();
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Search`;

    // Set moment locale every time the component mounts
    moment.locale("en");

    // Easter egg
    if (this.state.search === "rickroll") {
      window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
      return;
    }

    // console.log("SearchLocations.componentDidMount", this.props);
    if (!isEmpty(this.props.customTags) && !isEmpty(this.props.locationHierarchy)) {
      this.onRunQuery();
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const loadingQuery = this.props.loadingQueries[this.state.queryHash];
    const oldLoadingQuery = prevProps.loadingQueries[this.state.queryHash];

    // console.log("SearchLocations.componentDidUpdate", this.props);

    if (!this.state.hasLoadedView) {
      if (!isEmpty(this.props.customTags) && !isEmpty(this.props.locationHierarchy)) {
        this.onRunQuery();
      }

      return;
    }

    const queryChanged = loadingQuery === "completed" && oldLoadingQuery !== "completed";
    if (queryChanged || this.state.displayType !== prevState.displayType) {
      this.fetchData();
    }
  }

  storeQueryParams() {
    const queryParams = {};
    if (!isEmpty(this.state.search)) {
      queryParams.search = this.state.search;
    }

    if (!isEmpty(this.state.displayType)) {
      queryParams.type = this.state.displayType;
    }

    this.props.setLocationSearch(queryParams);
  }

  onDisplayTypeChanged(type) {
    const params = new URLSearchParams(this.props.history.location.search);

    if (type === null) {
      params.delete("type");
    }
    else {
      params.set("type", type);
    }

    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState({ displayType: type }, this.storeQueryParams);
  }

  onSearchChange(text) {
    this.setState({ search: text });
  }

  onSearchClear() {
    this.setState({ search: "" }, this.onSearchClick);
  }

  onRemoveTagClick(tag) {
    if (tag.id === "???") {
      this.onSearchClick();
    }
  }

  onSearchClick() {
    const params = new URLSearchParams(this.props.history.location.search);

    // Easter egg
    if (this.state.search === "rickroll") {
      window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
      return;
    }

    if (this.state.search === null || this.state.search === "") {
      params.delete("search");
    }
    else {
      params.set("search", this.state.search);
    }

    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.onRunQuery();
    this.storeQueryParams();
  }

  onRunQuery() {
    console.log("SearchLocations.onRunQuery");

    const startDate = moment().subtract(1, "month").startOf("month");
    const endDate = moment().subtract(1, "month").endOf("month");
    const queryHash = widgetActions.homeQueryHash(this.state.search, startDate, endDate);

    const topLocationIds = this.props.locationHierarchy.map(l => l.id);

    const topLocationQueries = topLocationIds.map(id => ({ property: "_id", value: id, operator: "eq", options: { includeDescendants: true } }));
    const query = {
      or: [
        ...topLocationQueries.map(topLocationQuery => ({ and: [topLocationQuery, { property: "type", value: `.*${this.state.search}.*`, operator: "regex" }] })),
        ...topLocationQueries.map(topLocationQuery => ({ and: [topLocationQuery, { property: "name", value: `.*${this.state.search}.*`, operator: "regex" }] }))
      ]
    };

    this.setState({ queryHash, hasLoadedView: true });

    // If we already have the data, skip the query
    if (this.props.loadingQueries[queryHash] === "completed") {
      this.fetchData();
    }
    else if (!this.props.loadingQueries[queryHash]) {
      this.props.newQueryLocations(query, queryHash);
    }

  }

  onSearchKeyPress(event) {
    if (event.key && event.key === "Enter") {
      event.preventDefault();
      this.onSearchClick();
    }
  }

  fetchData() {
    console.log("SearchLocations.fetchData");
    const widgetQueries = [];
    const locations = this.props.completedQueries[this.state.queryHash]?.locations ?? [];

    // Get locations queries
    if (this.state.displayType === "occupancy") {
      // Check if we have the data for the selected building
      const dataTypes = ["occupiedCompareDayOfWeek"];

      // Get core hours from locationHierarchy
      let coreTime = { start: { hours: 8, minutes: 0 }, end: { hours: 16, minutes: 0 } };
      for (var i = 0; i < locations.length; i++) {
        const location = locations[i];
        if (location.type != "region") {
          // Get core hours from parent building in locationHierarchy
          const firstLocation = getLocation({ id: "*", children: this.props.locationHierarchy }, location._id);
          if (firstLocation.coreTime) {
            coreTime = firstLocation.coreTime;
            break;
          }
        }
      }

      if (!isEmpty(locations)) {
        dataTypes.forEach(dataType => {
          if (!get(this.props.dashboardData, `${dataType}-${this.state.queryHash}`, false)) {
            widgetQueries.push({ id: dataType, buildingId: this.state.selectedBuildingId, wpaCategoryId: null, customTagId: null, coreTime, hash: this.state.queryHash });
          }
        });
      }
    }
    else if (this.state.displayType === "highcount") {
      // Check if we have the data for the selected building
      const dataTypes = ["maxOccupiedDayOfWeek"];

      // Get core hours from locationHierarchy
      let coreTime = { start: { hours: 8, minutes: 0 }, end: { hours: 16, minutes: 0 } };
      for (var i = 0; i < locations.length; i++) {
        const location = locations[i];
        if (location.type != "region") {
          // Get core hours from parent building in locationHierarchy
          const firstLocation = getLocation({ id: "*", children: this.props.locationHierarchy }, location._id);
          if (firstLocation.coreTime) {
            coreTime = firstLocation.coreTime;
            break;
          }
        }
      }

      if (!isEmpty(locations)) {
        dataTypes.forEach(dataType => {
          if (!get(this.props.dashboardData, `${dataType}-${this.state.queryHash}`, false)) {
            widgetQueries.push({ id: dataType, buildingId: this.state.selectedBuildingId, wpaCategoryId: null, customTagId: null, coreTime, hash: this.state.queryHash });
          }
        });
      }
    }
    else if (this.state.displayType === "people") {
      // Check if we have the data for the selected building
      const dataTypes = ["peopleCompareDayOfWeek"];

      // Get core hours from locationHierarchy
      let coreTime = { start: { hours: 8, minutes: 0 }, end: { hours: 16, minutes: 0 } };
      for (var i = 0; i < locations.length; i++) {
        const location = locations[i];
        if (location.type != "region") {
          // Get core hours from parent building in locationHierarchy
          const firstLocation = getLocation({ id: "*", children: this.props.locationHierarchy }, location._id);
          if (firstLocation.coreTime) {
            coreTime = firstLocation.coreTime;
            break;
          }
        }
      }

      if (!isEmpty(locations)) {
        dataTypes.forEach(dataType => {
          if (!get(this.props.dashboardData, `${dataType}-${this.state.queryHash}`, false)) {
            widgetQueries.push({ id: dataType, buildingId: this.state.selectedBuildingId, wpaCategoryId: null, customTagId: null, coreTime, hash: this.state.queryHash });
          }
        });
      }
    }

    // Remove duplicate widget queries
    const uniqueQueries = widgetQueries.filter((query, index, self) =>
      index === self.findIndex(t => (
        t.id === query.id && t.buildingId === query.buildingId && t.wpaCategoryId === query.wpaCategoryId && t.customTagId === query.customTagId
      ))
    );

    if (uniqueQueries.length > 0) {
      const safeLocationIds = locations.filter(location => location.type != "asset.workstation").map(location => location._id);
      uniqueQueries.forEach(query => {
        switch (query.id) {
          case "occupiedCompareDayOfWeek":
            if (safeLocationIds.length > 0) {
              const queryHashKey = `occupiedCompareDayOfWeek-${query.hash}`;
              this.props.getOccupiedCompareDayOfWeek(safeLocationIds, query.coreTime, queryHashKey);
            }
            break;
          case "maxOccupiedDayOfWeek":
            if (safeLocationIds.length > 0) {
              const queryHashKey = `maxOccupiedDayOfWeek-${query.hash}`;
              this.props.getMaxOccupiedDayOfWeek(safeLocationIds, query.coreTime, queryHashKey);
            }
            break;
          case "peopleCompareDayOfWeek":
            if (safeLocationIds.length > 0) {
              const queryHashKey = `peopleCompareDayOfWeek-${query.hash}`;
              this.props.getPeopleCompareDayOfWeek(safeLocationIds, query.coreTime, queryHashKey);
            }
            break;
          default:
            break;
        }
      });
    }
  }

  render() {
    const { isLoading } = this.props;
    // console.log("Home.FrontPage.state", this.state)
    // console.log("Home.FrontPage.props", this.props)

    if (isLoading) {
      return <Loader fullScreen />;
    }

    let customTags = [];

    const searchBox = (
      <FrontPageSearchBox
        query={{ customTags, text: this.state.search, placeholder: "What are you looking for?" }}
        onQueryChange={this.onSearchChange}
        onSearchClick={this.onSearchClick}
        onKeyDown={this.onSearchKeyPress}
        onClear={this.onSearchClear}
        onRemoveTagClick={this.onRemoveTagClick}
      />
    );

    const heading = (
      <div style={{ marginLeft: "auto", marginRight: "auto", flexGrow: 1, maxWidth: "800px", marginTop: "60px", marginBottom: "60px" }}>
        {searchBox}
      </div>
    );

    return (
      <OuterContainer>
        <InnerContainer>
          {heading}
          <ContentContainer>
            <Locations
              {...this.props}
              queryHash={this.state.queryHash}
              displayType={this.state.displayType}
              onDisplayTypeChanged={this.onDisplayTypeChanged}
            />
          </ContentContainer>
        </InnerContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationHierarchy: state.locations.hierarchy,
    customTags: state.customTags.list,
    loadingQueries: state.locationQuery.loadingQueries,
    completedQueries: state.locationQuery.completedQueries,
    dashboardData: state.dashboards.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getOccupiedCompareDayOfWeek: widgetActions.getOccupiedCompareDayOfWeek,
    getMaxOccupiedDayOfWeek: widgetActions.getMaxOccupiedDayOfWeek,
    getPeopleCompareDayOfWeek: widgetActions.getPeopleCompareDayOfWeek,
    newQueryLocations: locationActions.newQueryLocations,
    setLocationSearch: locationActions.setLocationSearch,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchLocations);

const OuterContainer = styled.div`
  position: relative;
  display: block;
  height: calc(100vh - 115px);
  width: 100%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }

  p, ol {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  width: ${props => props.$showSideBar ? "calc(100% - 345px)" : "100%"};
  max-height: 100%;
  height: 100%;
  overflow: auto;

  padding-left: ${props => props.$mobile ? "20px" : "40px"};
  padding-right: ${props => props.$mobile ? "20px" : "40px"};
  padding-bottom: 40px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;