import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faExclamation } from "@fortawesome/free-solid-svg-icons";
import AnimateHeight from "react-animate-height";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import moment from "moment";
import Caret from "./AppUsageCard/caret3_grey.png";
import AppUsageCard from "./AppUsageCard";
import { OuterContainer, InnerContainer, Row, Col } from ".";
import Table from "../../components/Table";
import { DARKGREY, GREEN, RED, RowIcon } from "../../components/RowIcon";
import ListCard from "../../components/ListCard";
import * as accountActions from "../../actions/account";
import * as dashboardActions from "../../actions/dashboards";
import styled from "styled-components";

class Statistics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expandedLocationId: null,
      showOfflineSensors: false,
      showOfflineGateways: false,
      appUsageExpanded: false,
    };

    this.onToggleExpand = this.onToggleExpand.bind(this);
    this.onToggleAppUsageExpand = this.onToggleAppUsageExpand.bind(this);
    this.onStatusClick = this.onStatusClick.bind(this);

    if (get(props, "offlineSensors.sensors", []).length === 0) {
      props.getOfflineSensors();
    }

    if (get(props, "offlineGateways.gateways", []).length === 0) {
      props.getOfflineGateways();
    }

    if (get(props, "sumUptime", null) === null) {
      props.getSystemUptime();
    }

    if (get(props, "selectedCompany.dashboardFeatures.showApp", false)) {
      props.getUniqueUserLogins();
    }
  }

  onToggleExpand(locationId) {
    if (locationId === this.state.expandedLocationId) {
      this.setState({ expandedLocationId: null });
    }
    else {
      this.setState({ expandedLocationId: locationId });
    }
  }

  onToggleAppUsageExpand() {
    this.setState({ appUsageExpanded: !this.state.appUsageExpanded });
  }

  onStatusClick(column, row) {
    console.log("onStatusClick", column, row);
    return {
      onClick: e => {
        let link;
        if (row && row.original.title === "Inactive sensors") {
          link = `/companies/${this.props.match.params.companyId}/home/sensors?offline=true`;
        }
        else if (row && row.original.title === "Inactive gateways") {
          link = `/companies/${this.props.match.params.companyId}/home/gateways?offline=true`;
        }

        if (e.metaKey || e.ctrlKey) {
          window.open(`${link}`);
        }
        else {
          this.props.history.push(link);
        }
      }
    }
  }

  statusTable() {

    const systemUptime = this.props.sumUptime;
    const totalSensorCount = get(this.props.offlineSensors, "totalSensorCount", 0);
    const totalGatewayCount = get(this.props.offlineGateways, "totalGatewayCount", 0);
    const offlineSensorCount = get(this.props.offlineSensors, "offlineSensorCount", 0);
    const offlineGatewayCount = get(this.props.offlineGateways, "offlineGatewayCount", 0);

    const offlineGatewayPercentage = totalGatewayCount ? (offlineGatewayCount / totalGatewayCount) : -1;
    let offlineGatewayColor = DARKGREY;
    if (offlineGatewayPercentage > 0.5) {
      offlineGatewayColor = DARKGREY; // RED;
    }
    else if (offlineGatewayPercentage < 0.1 && offlineGatewayPercentage > 0) {
      offlineGatewayColor = DARKGREY; // GREEN;
    }

    const offlineSensorPercentage = totalSensorCount ? (offlineSensorCount / totalSensorCount) : -1;
    let offlineSensorColor = DARKGREY;
    if (offlineSensorPercentage > 0.5) {
      offlineSensorColor = DARKGREY; // RED;
    }
    else if (offlineSensorPercentage < 0.1 && offlineSensorPercentage > 0) {
      offlineSensorColor = DARKGREY; // GREEN;
    }

    let rows = [
      {
        title: "System uptime (last 30 days)",
        value: systemUptime ? `${systemUptime}%` : "-",
        color: systemUptime ? (systemUptime > 90 ? GREEN : RED) : DARKGREY,
        onClick: false
      },
      {
        title: "Inactive gateways",
        value: totalGatewayCount ? `${offlineGatewayCount} of ${totalGatewayCount}` : "-",
        color: offlineGatewayColor,
        onClick: true
      },
      {
        title: "Inactive sensors",
        value: totalSensorCount ? `${offlineSensorCount} of ${totalSensorCount}` : "-",
        color: offlineSensorColor,
        onClick: true
      }
    ];

    return (
      <Table
        data={rows}
        noDataText={""}
        columns={[
          {
            accessorKey: "value",
            sortable: false,
            width: 50,
            style: { paddingLeft: "20px", marginRight: "10px" },
            cell: ({ row }) => {
              return <div style={{ justifyContent: "center", display: "flex", alignItems: "center", height: "100%" }}>
                <RowIcon tooltip="warning" bgColor={row.original.color} icon={faExclamation} size="sm" styles={{ width: "22px", height: "22px" }} />
              </div>
            }
          },
          {
            accessorKey: "title",
            cell: ({ row }) => {
              let valueElement;
              if (!isEmpty(row.original.value)) {
                valueElement = <div style={{ textAlign: "right", display: "inline-block", whiteSpace: "nowrap" }}>{row.original.value}</div>;
              }
              return (<><div style={{ display: "inline-block", overflowWrap: "break-word" }}>{row.original.title}</div>{valueElement}</>);
            },
            width: "auto"
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 50,
            cell: ({ row }) => (row.original.onClick && <div className="arrow" />)
          }
        ]}
        getTdProps={this.onStatusClick}
        hideHeaders
        className="-row-clickable -minimalist setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  render() {
    // console.log("Statistics state", this.state);
    // console.log("Statistics props", this.props);

    if (isEmpty(this.props.buildings) || isEmpty(this.props.buildingsTotal) || isEmpty(this.props.locationHierarchy) || isEmpty(this.props.locationBreadcrumbs)) {
      return null;
    }

    let buildingWithTotal = [...this.props.buildings, this.props.buildingsTotal];

    let buildingBlocks = buildingWithTotal.map((building, index) => {

      let totalSensorCount = 0;
      let totalSensorCountPreviousMonth = 0;
      let sensorsElements = building.sensors.map((sensor, index) => {
        totalSensorCount += sensor.totalCount;
        totalSensorCountPreviousMonth += sensor.totalCountPreviousMonth;

        let differenceIcon;
        let valueChange = sensor.totalCount - sensor.totalCountPreviousMonth;
        if (valueChange > 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
        }
        else if (valueChange < 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
        }
        else if (valueChange === 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
        }

        return (
          <BlockRow key={`sensor-${index}`}>
            <div style={{ display: "flex" }}>
              <BlockRowText>{sensor.vendor} {sensor.model}</BlockRowText>
            </div>

            <div style={{ display: "flex" }}>
              <BlockStatisticsContainer>
                <ValueContainer>
                  <Value>
                    {sensor.totalCount}
                  </Value>
                  <ValueChange>
                    ({differenceIcon}{Math.abs(valueChange)})
                  </ValueChange>
                </ValueContainer>
              </BlockStatisticsContainer>
            </div>
          </BlockRow>
        );
      });

      let totalGatewayCount = 0;
      let totalGatewayCountPreviousMonth = 0;
      let gatewaysElements = building.gateways.map((gateway, index) => {
        totalGatewayCount += gateway.totalCount;
        totalGatewayCountPreviousMonth += gateway.totalCountPreviousMonth;

        let differenceIcon;
        let valueChange = gateway.totalCount - gateway.totalCountPreviousMonth;
        if (valueChange > 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
        }
        else if (valueChange < 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
        }
        else if (valueChange === 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
        }

        return (
          <BlockRow key={`gateway-${index}`}>
            <div style={{ display: "flex" }}>
              <BlockRowText>{gateway.vendor} {gateway.model}</BlockRowText>
            </div>

            <div style={{ display: "flex" }}>
              <BlockStatisticsContainer>
                <Value>
                  {gateway.totalCount}
                </Value>
                <ValueChange>
                  ({differenceIcon}{Math.abs(valueChange)})
                </ValueChange>
              </BlockStatisticsContainer>
            </div>
          </BlockRow>
        );
      });

      let showExpand = false;
      if (building.sensors.length > 0 || building.gateways.length > 0) {
        showExpand = true;
      }

      let differenceSensorIcon;
      let sensorValueChange = totalSensorCount - totalSensorCountPreviousMonth;
      if (sensorValueChange > 0) {
        differenceSensorIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }
      else if (sensorValueChange < 0) {
        differenceSensorIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
      }
      else if (sensorValueChange === 0) {
        differenceSensorIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }

      let differenceGatewayIcon;
      let gatewayValueChange = totalGatewayCount - totalGatewayCountPreviousMonth;
      if (gatewayValueChange > 0) {
        differenceGatewayIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }
      else if (gatewayValueChange < 0) {
        differenceGatewayIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
      }
      else if (gatewayValueChange === 0) {
        differenceGatewayIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }


      let name = building.name;
      if (building.id === undefined) {
        name = "Total";
      }

      return (
        <Row key={`building-${index}`}>
          <Col size="lg" $noMargin>
            <BigBlock>
              <BlockContent>
                <div style={{ display: "flex", justifyContent: "space-between" }} onClick={() => showExpand && this.onToggleExpand(building.id)}>

                  <BlockInnerTitle>{name}</BlockInnerTitle>

                  <div style={{ display: "flex", alignItems: "center" }}>

                    <BlockStatisticsContainer style={{ width: "100px", flexDirection: "column", justifyContent: "center", padding: "12px 10px" }}>
                      <HeaderValueContainer>
                        <HeaderValue>
                          {totalSensorCount}
                        </HeaderValue>
                        <ValueChange>
                          ({differenceSensorIcon}{Math.abs(sensorValueChange)})
                        </ValueChange>
                      </HeaderValueContainer>
                      <div style={{ display: "inline-block", fontSize: "14px", color: "#555" }}>sensors</div>
                    </BlockStatisticsContainer>

                    <BlockStatisticsContainer style={{ width: "100px", flexDirection: "column", justifyContent: "center", padding: "12px 10px" }}>
                      <HeaderValueContainer>
                        <HeaderValue>
                          {totalGatewayCount}
                        </HeaderValue>
                        <ValueChange>
                          ({differenceGatewayIcon}{Math.abs(gatewayValueChange)})
                        </ValueChange>
                      </HeaderValueContainer>
                      <div style={{ display: "inline-block", fontSize: "14px", color: "#555" }}>gateways</div>
                    </BlockStatisticsContainer>

                    <ExpandContainer>
                      {showExpand && <ExpandIcon src={Caret} $rotated={this.state.expandedLocationId === building.id} />}
                    </ExpandContainer>
                  </div>

                </div>
                <Drawer>
                  <AnimateHeight duration={500} height={this.state.expandedLocationId === building.id ? "auto" : 0}>
                    <Content>
                      {sensorsElements}
                      {gatewaysElements}
                    </Content>
                  </AnimateHeight>
                </Drawer>
              </BlockContent>
            </BigBlock>
          </Col>
        </Row>
      );
    });

    // String of last month in english
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth = lastMonth.toLocaleString("en-us", { month: "long" });
    lastMonth = lastMonth.toLowerCase();

    return (
      <OuterContainer $darkBackground>
        <InnerContainer>
          <InnerContainerWrapper>
            <InnerContainerLeftSide>
              <Row $paddingTop="15px">
                <Col>
                  <h2>Sensors and gateways<BreadCrumb>({lastMonth})</BreadCrumb></h2>
                  <p>The number of sensors and gateways in your buildings and the change from the previous month.</p>
                </Col>
              </Row>
              {buildingBlocks}
            </InnerContainerLeftSide>
            <InnerContainerRightSide>
              <Row $paddingTop="15px">
                <Col>
                  <h2>Status</h2>
                  <p>App usage and system uptime.</p>
                </Col>
              </Row>
              {get(this.props, "selectedCompany.dashboardFeatures.showApp", false) && (
                <>
                  <AppUsageCard
                    title="App users"
                    subtitle={`total users as of ${moment().startOf("month").subtract(1, "month").endOf("month").format("MMMM D")}`}
                    value={this.props.appUsage.totalActivatedUsers}
                    valueChange={this.props.appUsage.diffTotalActivatedUsersLastMonthToPrevMonth}
                  />
                  <AppUsageCard
                    title="App usage"
                    subtitle={`active users in ${moment().startOf("month").subtract(1, "month").format("MMMM")}`}
                    value={this.props.appUsage.uniqueLogins}
                    valueChange={this.props.appUsage.diffUniqueLoginsLastMonthToPrevMonth}
                    monthData={this.props.appUsage.uniqueDailyLogins}
                    expanded={this.state.appUsageExpanded}
                    onToggleExpand={this.onToggleAppUsageExpand}
                  />
                </>
              )}
              <ListCard title="Installation status">
                {this.statusTable()}
              </ListCard>
            </InnerContainerRightSide>
          </InnerContainerWrapper>
        </InnerContainer>

      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    buildings: state.accountSummary.buildings,
    buildingsTotal: state.accountSummary.buildingsTotal,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    vortoIds: state.auth.vortoIds,

    sumUptime: state.uptime.sumUptime,
    appUsage: state.appUsage.usage,
    offlineSensors: state.offlineSensors.data,
    offlineGateways: state.offlineGateways.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAccountSummary: accountActions.getAccountSummary,
    getOfflineSensors: dashboardActions.getOfflineSensors,
    getOfflineGateways: dashboardActions.getOfflineGateways,
    getSystemUptime: dashboardActions.getSystemUptime,
    getUniqueUserLogins: dashboardActions.getUniqueUserLogins,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Statistics));

const InnerContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  column-gap: 40px;
  justify-content: flex-start;
`;

const InnerContainerLeftSide = styled.div`
  max-width: 960px;
  flex-grow: 1;
`;

const InnerContainerRightSide = styled.div`
  max-width: 400px;
  flex-grow: 1;
`;

const BigBlock = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  overflow: hidden;
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  // padding: 15px 20px 15px 20px;
  font-size: 16px;
  font-weight: 400;
`;

const BlockInnerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px 15px 20px;

  font-size: 20px;
  font-weight: 600;
  color: #222222;

  div { 
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }

  div:last-child {
    margin-left: 5px;
    
    font-size: 15px;
    font-weight: 600;
    color: #666666;

  }
`;

const BreadCrumb = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #666;
  // margin-bottom: 5px;
  margin-left: 5px;
`;

const ExpandContainer = styled.div`
  display: flex;
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
`;

const ExpandIcon = styled.img`
  width: 25px;
  height: 25px;
  transform: ${props => props.$rotated && "rotate(180deg)"};
`;

const Drawer = styled.div`
  overflow: visible;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-top: 1px solid #dbdbdb;
`;

const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 13px 20px 13px 20px;
  width: 100%;

  border-bottom: 1px solid #dbdbdb;

  &:last-child {
    border-bottom: none;
  }
`;

const BlockRowText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  width: 100%;

  // align text in the middle
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const BlockStatisticsContainer = styled.div`
  cursor: pointer;
  font-size: 16px;
  color: #222222;
  padding-right: 20px;
  width: ${props => props.width || "auto"};
  
  // align text in the middle
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const HeaderValueContainer = styled.div`
`;

const ValueContainer = styled.div`
  align-items: baseline;
`;

const Value = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 21px;
  line-height: 21px;
  font-weight: 600;
  color: #222222;
`;

const HeaderValue = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 21px;
  line-height: 21px;
  font-weight: 600;
  color: #222222;
`;

const ValueChange = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 600;
  color: #888888;
  margin-left: 4px;
  line-height: 20px
`;