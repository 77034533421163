import React, { useState, useEffect } from 'react';
import { WidgetValueType } from "./types";

interface PercentageTextProps {
  valueType: WidgetValueType; // Type of data
  targetValue: number; // Target percentage value
  duration: number; // Animation duration in milliseconds
}

const PercentageText: React.FC<PercentageTextProps> = ({ valueType, targetValue, duration }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    const startTime = Date.now();

    const animate = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Ensure progress does not exceed 1

      // Use an easing function to match the CSS transition flow, if necessary
      const easeInOut = (t: number) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      const easedProgress = easeInOut(progress);

      const newValue = easedProgress * (targetValue - currentValue) + currentValue;
      setCurrentValue(newValue);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        setCurrentValue(targetValue); // Ensure it ends precisely at the target value
      }
    };

    requestAnimationFrame(animate);
  }, [targetValue, duration]);

  let result = null;

  if (isNaN(currentValue)) {
    return (
      <>-</>
    );
  }

  switch (valueType) {
    case WidgetValueType.Count:
      result = Math.floor(currentValue);
      break;
    case WidgetValueType.Percentage:
      result = `${Math.round(currentValue)}%`;
      break;
    case WidgetValueType.Minutes:
      
      const totalMinutes = currentValue;

      // Calculate a label in the format "2h 3m"
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes - (hours * 60));

      if (hours === 0) {
        result = `${minutes}m`;
      }
      else if (hours > 99) {
        result = `${hours}h`;
      }
      else {
        result = `${hours}h ${minutes}m`;
      }

      break;
    default:
      break;
  }

  // console.log("result", result);

  return (
    <>
      {result}
    </>
  );
};

export default PercentageText;
