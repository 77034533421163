import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { RotatingLines } from "react-loader-spinner";
import get from "lodash/get";
import { compressToEncodedURIComponent } from "lz-string";
import ListCard from "../../../components/ListCard";
import * as reportActions from "../../../actions/reports";
import styled from "styled-components";
import HorizontalBarGraph from "../Widget/horizontalBarGraph";
import VerticalBarGraph from "../Widget/verticalBarGraph";
import { WidgetGraphType } from "../Widget/types";
import Sparkline from "../Widget/sparklineGraph";
import KPIBox from "../Widget/heatMap";
import TextValue from "../Widget/textValue";
import ValueListGraph from "../Widget/valueListGraph";
import { Info, Popup, PopupAnchor, SourceAnchor } from "ui";

class WidgetGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hoverOverPoint: null,
      hoverOverTooltipText: null,
    };
    this.onSingleItemClick = this.onSingleItemClick.bind(this);
  }

  onSingleItemClick(event, item) {
    // console.log("item", item)
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${item.locationId}`);
  }

  onReportClick(locationId, workplaceCategoryId) {
    const filters = [{
      lid: locationId,
      wpc: workplaceCategoryId
    }];

    // Default date range is last 30 days (minus last 2 days)
    const defaultStart = moment().subtract(1, "month").startOf("isoWeek");
    const lastAvailableDate = moment().subtract(2, "days").startOf("day");

    let defaultEnd = moment();
    if (defaultEnd.isAfter(lastAvailableDate)) {
      defaultEnd = lastAvailableDate.startOf("day");
    }

    const dateRanges = [{ startDate: defaultStart.toISOString(), endDate: defaultEnd.toISOString() }];
    this.props.setWorkplaceQuery(dateRanges, false, false, locationId, workplaceCategoryId, null);

    this.props.history.push(`/companies/${this.props.match.params.companyId}/reports?f=${compressToEncodedURIComponent(JSON.stringify(filters))}`);
  }

  // Return a list of span elements with regular, bold, and italic text
  parseDescription(description) {

    if (!description) {
      return null;
    }

    const elements = [];
    let text = description;

    // Regular expression to match bold (**text**) and italic (*text*) patterns
    let match = text.match(/(\*\*.*?\*\*)|(\*.*?\*)/);

    while (match) {
      const index = match.index;

      // Get the matched text
      let matchedText = match[0];

      // Add any text before the match as a regular span
      if (index > 0) {
        elements.push(
          <span key={"text" + elements.length} style={{ color: "#555" }}>
            {text.substring(0, index)}
          </span>
        );
      }

      // Determine if the matched text is bold (**text**) or italic (*text*)
      if (matchedText.startsWith("**")) {
        // Bold text (remove ** and apply bold styling)
        const boldText = matchedText.replace(/\*\*/g, "");
        elements.push(
          <span
            key={"bold" + elements.length}
            style={{ fontWeight: 600, color: "#111", fontSize: "18px" }}
          >
            {boldText}
          </span>
        );
      }
      else if (matchedText.startsWith("*")) {
        // Italic text (remove * and apply italic styling)
        const italicText = matchedText.replace(/\*/g, "");
        elements.push(
          <span
            key={"italic" + elements.length}
            style={{ fontStyle: "italic", color: "#111", fontSize: "18px" }}
          >
            {italicText}
          </span>
        );
      }

      // Update the text by removing the processed part
      text = text.substring(index + matchedText.length);

      // Look for the next match
      match = text.match(/(\*\*.*?\*\*)|(\*.*?\*)/);
    }

    // Add any remaining text as a regular span
    if (text) {
      elements.push(
        <span key={"endtext"} style={{ color: "#555" }}>
          {text}
        </span>
      );
    }

    return (
      <p style={{ marginTop: "15px", marginBottom: "0px", lineHeight: "22px" }}>
        {elements}
      </p>
    );
  }

  render() {
    // const { isLoading } = this.props;
    // console.log("Home.WidgetGrid.state", this.state);
    console.log("Home.WidgetGrid.props", this.props);

    // if (isLoading) {
    //   return <Loader fullScreen />;
    // }

    if (!this.props.building) {
      return null;
    }

    // Show tooltip if hovering over a point with a tooltip
    let tooltip = null;
    if (this.state.hoverOverPoint && this.state.hoverOverTooltipText) {
      tooltip = (
        <Popup
          isVisible={true}
          sourceRect={this.state.hoverOverPoint}
          sourceAnchor={SourceAnchor.CENTER}
          popupAnchor={PopupAnchor.BOTTOM_CENTER}
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            borderWidth: "1px",
            borderColor: "#ccc",
            borderStyle: "solid",
            zIndex: 1000,
            boxShadow: "2px 2px 5px 0px #0004"
          }}
        >
          <div style={{ padding: "10px", fontSize: "14px", lineHeight: "20px" }}>
            {this.state.hoverOverTooltipText}
          </div>
        </Popup>
      );
    }

    // Build widgets
    const widgets = this.props.widgets;
    const content = (
      <>
        {/* <StatusBar>
          <ListCard>
            <div style={{ display: "flex", margin: "15px 20px", boxSizing: "border-box", color: "#222", fontWeight: "400", columnGap: "50px", rowGap: "5px", flexWrap: "wrap" }}>
              <div style={{ display: "flex" }}>
                <span style={{ color: "#666", fontSize: "16px", fontWeight: "400", marginRight: "8px" }}>Rooms:</span>
                <span style={{ color: "#111", fontSize: "16px", fontWeight: "600" }}>40</span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: "#666", fontSize: "16px", fontWeight: "400", marginRight: "8px" }}>People capacity:</span>
                <span style={{ color: "#111", fontSize: "16px", fontWeight: "600" }}>370</span>
              </div>
            </div>
          </ListCard>
        </StatusBar> */}
        { tooltip }
        <Tableau>
          {
            widgets.map((widget, index) => {

              let selectedBuildingId = this.props.building.id;
              let selectedWpaCategoryId = this.props.page.wpaCategoryId ?? null;
              let selectedCustomTagId = this.props.customTagId ?? null;

              if (this.props.page.id === "overview") {
                // Use data source ids from the widget, in overview, if it has any
                const selectedWpaCategory = this.props.wpaCategories.find(wpaCategory => widget.wpaCategoryNames?.includes(wpaCategory.name));
                selectedWpaCategoryId = selectedWpaCategory?._id ?? null;
                selectedCustomTagId = widget.customTagId ?? null;

              }
              else if (selectedWpaCategoryId === null) {
                // Use data source ids from the widget, in overview, if it has any
                selectedCustomTagId = widget.customTagId ?? null;
              }

              let widgetContent = null;
              let widgetLocationCount = -1;
              let dataPointCapacity;
              let widgetData = [];
              let widgetKey = `${selectedBuildingId}-${selectedWpaCategoryId}-${selectedCustomTagId}`;
              const sourceData = get(this.props.widgetData, `[${widgetKey}].${widget.id}`, []);
              const loading = get(this.props.widgetLoading, `[${widgetKey}].${widget.id}`, false);

              let chartDescription = "";
              const lastMonth = moment.utc().subtract(1, "month").startOf("month");
              const lastLastMonth = moment.utc().subtract(2, "month").startOf("month");
              // console.log("widgetKey", `${widgetKey}.${widget.id}`);
              // console.log("sourceData", sourceData);

              switch (widget.type) {
                case WidgetGraphType.ValueList:

                  widget.dataLayout.forEach(dataPointLayout => {
                    const dataPoint = get(sourceData, 0, {});
                    widgetData.push({
                      name: get(dataPoint, dataPointLayout.nameProperty, dataPointLayout.name),
                      value: get(dataPoint, dataPointLayout.property, dataPointLayout.value),
                      description: dataPointLayout.description,
                      color: dataPointLayout.color,
                      locationId: data.locationId,
                    });
                  });

                  // Get the number of locations
                  widgetLocationCount = widgetData.length;

                  // Build the chart footer
                  chartDescription = widget.description;

                  widgetContent = (
                    <ValueListGraph
                      data={widgetData}
                      valueType={widget.valueType}
                      max={widget.max}
                      showMaxLine={widget.showMaxLine}
                      onClick={this.onSingleItemClick}
                      hoverText="Go to location"
                    />
                  );

                  break;
                case WidgetGraphType.DynamicValueList:

                  const layout = widget.dataLayout;
                  const dataList = sourceData?.results?.filter(data => data[widget.dateKey] === layout.datetime) ?? [];
                  dataList.forEach(data => {
                    widgetData.push({
                      name: get(data, layout.nameProperty, layout.name),
                      value: get(data, layout.property, layout.value),
                      description: layout.description,
                      color: layout.color,
                      locationId: data.locationId,
                    });
                  });

                  // Get the number of locations
                  widgetLocationCount = sourceData.count;

                  // Build the chart footer
                  chartDescription = widget.description;

                  widgetContent = (
                    <ValueListGraph
                      data={widgetData}
                      valueType={widget.valueType}
                      max={widget.max}
                      showMaxLine={widget.showMaxLine}
                      onClick={this.onSingleItemClick}
                      hoverText="Go to location"
                    />
                  );

                  break;
                case WidgetGraphType.HorizontalBar:

                  let hasDescription = false;
                  widget.dataLayout.forEach(dataPointLayout => {
                    const dataPoint = get(sourceData, 0, {});
                    widgetData.push({
                      value: get(dataPoint, dataPointLayout.property, 0),
                      description: dataPointLayout.description,
                      color: dataPointLayout.color
                    });

                    if (dataPointLayout.description) {
                      hasDescription = true;
                    }

                    if (dataPoint.locationCount !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationCount);
                    }

                    if (dataPoint.capacity !== undefined) {
                      dataPointCapacity = dataPoint.capacity;
                    }
                  });

                  // Build the chart footer
                  chartDescription = widget.description;

                  widgetContent = (
                    <HorizontalBarGraph
                      data={widgetData}
                      valueType={widget.valueType}
                      max={dataPointCapacity ?? widget.max}
                      showMaxLine={widget.showMaxLine}
                      showDescription={hasDescription}
                    />
                  );

                  break;
                case WidgetGraphType.VerticalBar:

                  widget.dataLayout.map(dataPointLayout => {
                    // Get the data point with the same date/weekday/hour etc. as the layout
                    const dataPoint = sourceData.find(data => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
                    // console.log("dataPoint", dataPoint);
                    // console.log("dataPointLayout", dataPointLayout);
                    widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

                    if (dataPoint.locationCount !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationCount);
                    }

                    if (dataPoint.capacity !== undefined) {
                      dataPointCapacity = dataPoint.capacity;
                    }
                    
                  });

                  // Build the chart footer
                  chartDescription = widget.description;

                  widgetContent = (
                    <VerticalBarGraph
                      data={widgetData}
                      valueType={widget.valueType}
                      max={dataPointCapacity ?? widget.max}
                      description={widget.dataDescription}
                      showMaxLine={widget.showMaxLine}
                    />
                  );

                  break;
                case WidgetGraphType.KPIBox:

                  widget.dataLayout.map(dataPointLayout => {
                    // Get the data point with the same date/weekday/hour etc. as the layout
                    const dataPoint = sourceData.find(data => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
                    widgetData.push({
                      value: get(dataPoint, dataPointLayout.property, 0),
                      change: get(dataPoint, dataPointLayout.change, undefined),
                      percentageChange: get(dataPoint, dataPointLayout.percentageChange, undefined),
                      total: get(dataPoint, dataPointLayout.total, undefined),
                      description: dataPointLayout.description,
                      color: dataPointLayout.color
                    });

                    if (dataPoint.locationCount !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationCount);
                    }
                  });

                  // Build the chart footer
                  chartDescription = widget.description;

                  widgetContent = (
                    <KPIBox
                      data={widgetData}
                      valueType={widget.valueType}
                      max={widget.max}
                      description={widget.dataDescription}
                    />
                  );

                  break;
                case WidgetGraphType.ChangeBarGraph:

                  widget.dataLayout.map((dataPointLayout, index) => {
                    let dataPoint = {};
                    if (widget.dateKey) {
                      dataPoint = sourceData.find(data => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
                    }
                    else {
                      dataPoint = sourceData[index] ?? {};
                    }
                    widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

                    if (dataPoint.locationCount !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationCount);
                    }
                  });

                  // Build the chart footer
                  chartDescription = widget.description;

                  widgetContent = (
                    <VerticalBarGraph
                      data={widgetData}
                      valueType={widget.valueType}
                      max={widget.max}
                      description={widget.dataDescription}
                      showChangeBar={true}
                    />
                  );

                  break;
                case WidgetGraphType.ChangeBarGraph2:

                  widget.dataLayout.map((dataPointLayout, index) => {
                    let dataPoint = {};
                    if (widget.dateKey) {
                      dataPoint = sourceData.find(data => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
                    }
                    else {
                      dataPoint = sourceData[index] ?? {};
                    }
                    widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

                    if (dataPoint.locationCount !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationCount);
                    }
                  });

                  // Build the chart footer
                  // chartDescription = `The duration no resources were not in use in ${locationName}.`,

                  // Build the chart footer
                  if (widgetData.length === 2) {
                    let value1 = Math.round(10 * widgetData[0].value / 60) / 10;
                    let value2 = Math.round(10 * widgetData[1].value / 60) / 10;
                    let change = Math.abs(Math.round(10 * (value1 - value2)) / 10);
                    if (value1 === value2) {
                      chartDescription = `All ${widget.resourceName} where empty for a total of **${value1}** hours in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is the same amount as in ${lastLastMonth.format("MMMM")}.`;
                    }
                    else if (value1 > value2) {
                      chartDescription = `All ${widget.resourceName} where empty for a total of **${value1}** hours in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours more than in ${lastLastMonth.format("MMMM")}.`;
                    }
                    else {
                      chartDescription = `All ${widget.resourceName} where empty for a total of **${value1}** hours in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours less than in ${lastLastMonth.format("MMMM")}.`;
                    }
                  }

                  widgetContent = (
                    <VerticalBarGraph
                      data={widgetData}
                      valueType={widget.valueType}
                      max={widget.max}
                      description={widget.dataDescription}
                      showChangeBar={false}
                    />
                  );

                  break;
                case WidgetGraphType.MonthlyDurationEmpty:

                  widget.dataLayout.map((dataPointLayout, index) => {
                    let dataPoint = {};
                    if (widget.dateKey) {
                      dataPoint = sourceData.find(data => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
                    }
                    else {
                      dataPoint = sourceData[index] ?? {};
                    }
                    widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

                    if (dataPoint.locationCount !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationCount);
                    }
                  });

                  // Build the chart footer
                  if (widgetData.length === 2) {
                    let value1 = Math.round(10 * widgetData[0].value / 60) / 10;
                    let value2 = Math.round(10 * widgetData[1].value / 60) / 10;
                    let change = Math.abs(Math.round(10 * (value1 - value2)) / 10);
                    if (value1 === value2) {
                      chartDescription = `All ${widget.resourceName} where empty at the same time for a total of **${value1}** hours in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is the same amount as in ${lastLastMonth.format("MMMM")}.`;
                    }
                    else if (value1 > value2) {
                      chartDescription = `All ${widget.resourceName} where empty at the same time for a total of **${value1}** hours in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours more than in ${lastLastMonth.format("MMMM")}.`;
                    }
                    else {
                      chartDescription = `All ${widget.resourceName} where empty at the same time for a total of **${value1}** hours in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours less than in ${lastLastMonth.format("MMMM")}.`;
                    }

                    widgetContent = (
                      <TextValue
                        targetValue={widgetData[0].value}
                        valueType={widget.valueType}
                        duration={1000}
                      />
                    );
                  }

                  break;
                case WidgetGraphType.MonthlyDurationFull:
                case WidgetGraphType.MonthlyDurationAtCapacity:

                  widget.dataLayout.map((dataPointLayout, index) => {
                    let dataPoint = {};
                    if (widget.dateKey) {
                      dataPoint = sourceData.find(data => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
                    }
                    else {
                      dataPoint = sourceData[index] ?? {};
                    }
                    widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

                    if (dataPoint.locationCount !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationCount);
                    }
                  });

                  // Build the chart footer
                  if (widgetData.length === 2) {
                    let value1 = Math.round(10 * widgetData[0].value / 60) / 10;
                    let value2 = Math.round(10 * widgetData[1].value / 60) / 10;
                    let change = Math.abs(Math.round(10 * (value1 - value2)) / 10);
                    if (value1 === value2) {
                      // No rooms where available
                      chartDescription = `All ${widget.resourceName} where occupied at the same time for a total of **${value1}** hours in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is the same amount as in ${lastLastMonth.format("MMMM")}.`;
                    }
                    else if (value1 > value2) {
                      chartDescription = `All ${widget.resourceName} where occupied at the same time for a total of **${value1}** hours in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours more than in ${lastLastMonth.format("MMMM")}.`;
                    }
                    else {
                      chartDescription = `All ${widget.resourceName} where occupied at the same time for a total of **${value1}** hours in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours less than in ${lastLastMonth.format("MMMM")}.`;
                    }

                    widgetContent = (
                      <TextValue
                        targetValue={widgetData[0].value}
                        valueType={widget.valueType}
                        duration={1000}
                      />
                    );
                  }

                  break;
                case WidgetGraphType.Sparkline:

                  widget.dataLayout.map((dataPointLayout, index) => {
                    const dataPoint = get(sourceData, index, {});
                    widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

                    if (dataPoint.locationCount !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationCount);
                    }
                  });

                  // Build the chart footer
                  chartDescription = widget.description;

                  widgetContent = (
                    <Sparkline
                      data={widgetData}
                      valueType={widget.valueType}
                      max={widget.max}
                      color={widget.color}
                      description={widget.dataDescription}
                    />
                  );

                  break;
                case WidgetGraphType.UtilizationSparklineMonth:

                  let max = -100000;
                  let min = 100000;
                  widget.dataLayout.map((dataPointLayout, index) => {
                    const dataPoint = get(sourceData, index, {});
                    widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });
                    max = Math.max(max, get(dataPoint, dataPointLayout.property, 0));
                    min = Math.min(min, get(dataPoint, dataPointLayout.property, 0));

                    if (dataPoint.locationCount !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationCount);
                    }
                  });

                  // Build the chart footer
                  if (max === min) {
                    chartDescription = `There where no activity in ${this.props.building.name} in ${lastMonth.format("MMMM")}.`;
                  }
                  else {
                    chartDescription = `The highest utilization reached in ${this.props.building.name} in ${lastMonth.format("MMMM")} was **${max * 100}%**.`;
                  }

                  widgetContent = (
                    <Sparkline
                      data={widgetData}
                      valueType={widget.valueType}
                      max={widget.max}
                      color={widget.color}
                      description={widget.dataDescription}
                    />
                  );

                  break;
                case WidgetGraphType.HorizontalCompareMonths:

                  let hasDescription1 = false;
                  let sum1 = 0;
                  let sumChange1 = 0;
                  let total1 = 0;
                  widget.dataLayout.forEach(dataPointLayout => {
                    // Get the data point with the same date/weekday/hour etc. as the layout
                    const dataPoint = sourceData.find(data => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
                    widgetData.push({
                      value: get(dataPoint, dataPointLayout.property, 0),
                      description: dataPointLayout.description,
                      color: dataPointLayout.color
                    });

                    sum1 += get(dataPoint, dataPointLayout.property, 0);
                    sumChange1 += get(dataPoint, dataPointLayout.change, 0);
                    total1 = get(dataPoint, "total", 0);

                    if (dataPointLayout.description) {
                      hasDescription1 = true;

                    }

                    if (dataPoint.total !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.total);
                    }
                  });

                  // Build the chart footer
                  if (sum1 === 0) {
                    if (sumChange1 === 0) {
                      chartDescription = `There where no ${widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}.`;
                    }
                    else {
                      chartDescription = `There where no ${widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${Math.abs(sumChange1)}** less than in ${lastLastMonth.format("MMMM")}.`;
                    }
                  }
                  else {
                    if (sumChange1 === 0) {
                      chartDescription = `There where **${sum1}** ${sum1 === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is the same as in ${lastLastMonth.format("MMMM")}.`;
                    }
                    else if (sumChange1 < 0) {
                      chartDescription = `There where **${sum1}** ${sum1 === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${Math.abs(sumChange1)}** less than in ${lastLastMonth.format("MMMM")}.`;
                    }
                    else {
                      chartDescription = `There where **${sum1}** ${sum1 === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${sumChange1}** more than in ${lastLastMonth.format("MMMM")}.`;
                    }
                  }

                  widgetContent = (
                    <HorizontalBarGraph
                      data={widgetData}
                      valueType={widget.valueType}
                      max={total1 ?? widget.max}
                      showDescription={hasDescription1}
                    />
                  );

                  break;
                case WidgetGraphType.MonthlyUtilizationDistribtion:

                  widget.dataLayout.map(dataPointLayout => {
                    // Get the data point with the same date/weekday/hour etc. as the layout
                    const dataPoint = sourceData.find(data => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
                    widgetData.push({
                      value: get(dataPoint, dataPointLayout.property, 0),
                      change: get(dataPoint, dataPointLayout.change, 0),
                      description: dataPointLayout.description,
                      color: dataPointLayout.color
                    });

                    if (dataPoint.total !== undefined) {
                      widgetLocationCount = Math.max(widgetLocationCount, dataPoint.total);
                    }
                  });

                  // Build the chart footer
                  if (widgetData.length === 5) {
                    let littleUsed = widgetData[0].value + widgetData[1].value;
                    let littleUsedChange = widgetData[0].change + widgetData[1].change;

                    if (littleUsed === 0) {
                      if (littleUsedChange === 0) {
                        chartDescription = `There where no ${widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}.`;
                      }
                      else {
                        chartDescription = `There where no ${widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${Math.abs(littleUsedChange)}** less than in ${lastLastMonth.format("MMMM")}.`;
                      }
                    }
                    else {
                      if (littleUsedChange === 0) {
                        chartDescription = `There where **${littleUsed}** ${littleUsed === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is the same as in ${lastLastMonth.format("MMMM")}.`;
                      }
                      else if (littleUsedChange < 0) {
                        chartDescription = `There where **${littleUsed}** ${littleUsed === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${Math.abs(littleUsedChange)}** less than in ${lastLastMonth.format("MMMM")}.`;
                      }
                      else {
                        chartDescription = `There where **${littleUsed}** ${littleUsed === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${this.props.building.name} in ${lastMonth.format("MMMM")}. That is **${littleUsedChange}** more than in ${lastLastMonth.format("MMMM")}.`;
                      }
                    }
                  }

                  widgetContent = (
                    <VerticalBarGraph
                      data={widgetData}
                      valueType={widget.valueType}
                      max={widget.max}
                      description={widget.dataDescription}
                      showMaxLine={widget.showMaxLine}
                    />
                  );

                  break;
                default:
                  break;
              }

              // Replace $locationName in chartDescription with the location name
              chartDescription = chartDescription.replace(/\$locationName/g, this.props.building.name);

              let footerDescription = this.props.building?.name;
              let footerCount = "";
              if (this.props.page.id === "overview") {
                footerDescription = `${widget.wpaCategoryNames[0]} in ${this.props.building?.name}`;
              }
              else if (this.props.page) {
                footerDescription = `${this.props.page.name} in ${this.props.building?.name}`;
              }

              // console.log("widgetData", widgetData);
              let footer = null;
              if (widgetLocationCount > -1) {
                if (widgetLocationCount === 1) {
                  footerCount = `${widgetLocationCount} location`;
                }
                else {
                  footerCount = `${widgetLocationCount} locations`;
                }

                footer = (
                  <>
                    <div style={{ display: "block", fontWeight: 400, color: "#666", fontSize: "13px" }}>{footerDescription}</div>
                    <div style={{ display: "block", fontWeight: 400, color: "#666", fontSize: "13px" }}>{footerCount}</div>
                  </>
                );
              }
              else {
                footer = (
                  <>
                    <div style={{ display: "block", fontWeight: 400, color: "#666", fontSize: "13px" }}>{footerDescription}</div>
                  </>
                );
              }

              let info = null;
              if (loading) {
                info = <RotatingLines strokeColor="#777" width="20px" />;
              }
              else {
                info = <Info text={"test"} color popupBelow />;
              }

              return (
                <div key={index} style={{ minWidth: "300px", maxWidth: "400px", breakInside: "avoid" }}>
                  <ListCard key={index} inGrid footer={footer} useBottomPadding>
                    <div style={{ display: "block", margin: "20px", boxSizing: "border-box" }}>
                      <div style={{ marginBottom: "10px" }}>
                        <h3 style={{ color: "#222", marginBottom: "2px" }}>{widget.title}</h3>
                        { widget.subtitle && <p style={{ color: "#666" }}>{widget.subtitle}</p> }
                      </div>
                      <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                        { info }
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box" }}>
                        { widgetContent }
                        { this.parseDescription(chartDescription) }
                      </div>
                    </div>
                  </ListCard>
                </div>
              );
            })
          }
        </Tableau>
      </>
    );

    return content;
  }
}

function mapStateToProps(state) {
  return {
    company: state.auth.selectedCompany,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    customTags: state.customTags.list,
    widgetData: state.widgets.data,
    widgetLoading: state.widgets.loading,
    wpaCategories: state.report.wpaCategories,
    widgetProfile: state.widgets.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getWpaCategories: reportActions.getWpaCategories,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetGrid);

// Status bar with the same width as the tableau
const StatusBar = styled.div`
  margin-bottom: 20px;
  width: 100%;
  // width: fit-content;
  max-width: 1240px;

  @media (max-width: 1450px) and (min-width: 1060px) {
    max-width: 820px;
  }

  @media (max-width: 1060px) {
    max-width: 400px;
  }
`;

// Independent columns where items have min-width 370px and max-width 400px
const Tableau = styled.div`
  column-fill: balance;
  column-gap: 20px;
  column-width: auto;

  column-count: 3;
  width: 100%;
  max-width: 1240px;

  // Max width for 2 columns: 
  // 370 * 3 + 20 * 2 = 1150 + sidebar (260) + padding (40) = 1450

  @media (max-width: 1450px) and (min-width: 1060px) {
    column-count: 2;
    max-width: 820px;
  }

  // Max width for 1 column: 
  // 370 * 2 + 20 * 1 = 760 + sidebar (260) + padding (40) = 1060

  @media (max-width: 1060px) {
    column-count: 1;
    max-width: 400px;
  }
`;